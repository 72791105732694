import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Order } from '@surface-elements/orders/domain';
import { OrderFormValue, OrderInvoicingFormValue } from './order.state';
import { HttpErrorResponse } from '@angular/common/http';

export const loadOrders = createAction('[ORDER] Load Orders');

export const loadOrdersSuccess = createAction(
  '[ORDER] Load Orders Success',
  props<{ orders: Order[] }>()
);

export const loadOrdersFailure = createAction(
  '[ORDER] Load Orders Fail',
  props<{ error: string }>()
);

export const createOrder = createAction(
  '[ORDER] Create order',
  props<{ order: Order }>()
);

export const createOrderFail = createAction(
  '[ORDER] Create order Fail',
  props<{ error: string }>()
);

export const createOrderSuccess = createAction(
  '[ORDER] Create order Success',
  props<{ order: Order }>()
);

export const updateOrder = createAction(
  '[ORDER] Update Order',
  props<{ order: Order; id: string }>()
);

export const updateOrderSuccess = createAction(
  '[ORDER] Update Order Success',
  props<Update<Order>>()
);

export const updateOrderFailure = createAction(
  '[ORDER] Update Order Fail',
  props<{ error: string }>()
);

export const updateOrdersInvoiceDate = createAction(
  '[ORDER] Update Orders Invoice Date',
  props<{ invoiceDate: string; orderIds: string[] }>()
);

export const updateOrdersInvoiceDateSuccess = createAction(
  '[ORDER] Update Orders Invoice Date Success',
  props<{ payload: Update<Order>[] }>()
);

export const updateOrdersInvoiceDateFailure = createAction(
  '[ORDER] Update Orders Invoice Date Fail',
  props<{ error: HttpErrorResponse }>()
);
export const softDeleteOrder = createAction(
  '[ORDER] Soft Delete Order',
  props<{ order: Order; jobNumber: number; id: string }>()
);

export const softDeleteOrderSuccess = createAction(
  '[ORDER] Soft Delete Order Success',
  props<Update<Order>>()
);

export const setCurrentOrder = createAction(
  '[ORDER] Set Current Order',
  props<{ currentOrderId: string | null }>()
);

export const selectOrderIds = createAction(
  '[ORDER] Select Order Ids',
  props<{ selectedIds: string[] | null }>()
);

export const clearCurrentOrder = createAction('[ORDER] Clear Current Order');

export const initializeCurrentOrder = createAction(
  '[ORDER] Initialize Current Order'
);

export const SetEditingValueOrderAction = createAction(
  '[ORDER FORM] Set editing value',
  props<{ editValue: OrderFormValue }>()
);

export const SetEditingValueOrderInvoiceAction = createAction(
  '[INVOICE ORDER FORM] Set editing value',
  props<{ editValue: OrderInvoicingFormValue }>()
);

export const resetMyForm = createAction('[ORDER FORM] Reset form');
