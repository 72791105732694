import { Route } from '@angular/router';
import { AuthGuardService } from '@surface-elements/shared/data-access-navigation';

export const omsFeatureShellRoutes: Route[] = [
  {
    path: 'login',
    loadChildren: () =>
      import('@surface-elements/login/feature-page').then((m) => m.LoginFeaturePageModule),
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('@surface-elements/accounts/feature-shell').then((m) => m.AccountsFeatureShellModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('@surface-elements/contacts/feature-page').then((m) => m.ContactsFeaturePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'jobs',
    loadChildren: () => import('@surface-elements/jobs/feature-shell').then((m) => m.JobsFeatureShellModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('@surface-elements/orders/feature-page').then((m) => m.OrdersFeaturePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('@surface-elements/reports/feature-page').then((m) => m.ReportsFeaturePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('@surface-elements/settings/feature-page').then(
        (m) => m.SettingsFeaturePageModule
      ),
    canActivate: [AuthGuardService],
  },
  { path: '', redirectTo: '/jobs', pathMatch: 'full' },
];
