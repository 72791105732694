import { createReducer, on } from '@ngrx/store';

import {
  initialState,
  orderAdapter,
  OrderFormValue,
  State,
  ORDER_FORM_ID,
  INVOICING_FORM_ID,
  OrderInvoicingFormValue,
  INITIAL_INVOICING_FORM_STATE,
} from './order.state';

import * as orderActions from './order.actions';
import {
  createFormGroupState,
  onNgrxForms,
  updateGroup,
  updateArray,
  validate,
  wrapReducerWithFormStateUpdate,
  SetValueAction,
  formStateReducer,
} from 'ngrx-forms';
import { greaterThanOrEqualTo, required } from 'ngrx-forms/validation';
import { Activity, AreaSelection, Service } from '@surface-elements/shared/shared-ui';

function setActiveStatus(state: State): State {
  const updatedState: State = { ...state };
  const formStateValue = state.orderForm.value;

  if (formStateValue.invoiceDate === null) {
    updatedState.orderForm = formStateReducer<OrderFormValue>(
      updatedState.orderForm,
      new SetValueAction(updatedState.orderForm.controls.active.id, true)
    );
  } else if (formStateValue.active === null) {
    updatedState.orderForm = formStateReducer<OrderFormValue>(
      updatedState.orderForm,
      new SetValueAction(updatedState.orderForm.controls.active.id, false)
    );
  }
  return updatedState;
}

const reducer = createReducer<State>(
  initialState,
  onNgrxForms(),
  on(orderActions.SetEditingValueOrderAction, (state, action): State => {
    const stateEdit = {
      ...state,
      orderForm: createFormGroupState<OrderFormValue>(
        ORDER_FORM_ID,
        action.editValue
      ),
    };
    return setActiveStatus(stateEdit);
  }),
  on(orderActions.SetEditingValueOrderInvoiceAction, (state, action): State => {
    const stateEdit = {
      ...state,
      orderInvoicingForm: createFormGroupState<OrderInvoicingFormValue>(
        INVOICING_FORM_ID,
        action.editValue
      ),
    };
    return setActiveStatus(stateEdit);
  }),
  on(orderActions.resetMyForm, (state): State => {
    return {
      ...state,
      orderInvoicingForm: createFormGroupState<OrderInvoicingFormValue>(
        INVOICING_FORM_ID,
        INITIAL_INVOICING_FORM_STATE.value
      )
    }
  }),
  on(orderActions.loadOrdersSuccess, (state, action): State => {
    return orderAdapter.setAll(action.orders, {
      ...state,
      error: '',
    });
  }),
  on(orderActions.loadOrdersFailure, (state, action): State => {
    return {
      ...state,
      entities: {},
      error: action.error,
    };
  }),
  on(orderActions.updateOrderSuccess, (state, action): State => {
    return orderAdapter.updateOne(action, state);
  }),
  on(orderActions.updateOrderFailure, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(orderActions.createOrderSuccess, (state, action): State => {
    return orderAdapter.addOne(action.order, state);
  }),
  on(orderActions.createOrderFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(orderActions.updateOrdersInvoiceDateSuccess, (state, action): State => {
    return orderAdapter.updateMany(action.payload, state);
  }),
  on(orderActions.updateOrdersInvoiceDateFailure, (state, action): State => {
    return {
      ...state,
      error: action.error.message,
    };
  }),
  on(orderActions.softDeleteOrderSuccess, (state, action): State => {
    return orderAdapter.updateOne(action, state);
  }),
  on(orderActions.setCurrentOrder, (state, action): State => {
    return {
      ...state,
      selectedOrderId: action.currentOrderId,
    };
  }),
  on(orderActions.selectOrderIds, (state, action): State => {
    return {
      ...state,
      selectedIds: action.selectedIds,
    };
  }),
  on(orderActions.clearCurrentOrder, (state): State => {
    return {
      ...state,
      selectedOrderId: null,
    };
  }),
  on(orderActions.initializeCurrentOrder, (state): State => {
    return {
      ...state,
      selectedOrderId: '',
    };
  })
);

const validateOrderForm = updateGroup<OrderFormValue>({
  quoteSqFt: validate(greaterThanOrEqualTo(0)),
  actualSqFt: validate(greaterThanOrEqualTo(0)),
  quotedAmount: validate(greaterThanOrEqualTo(0)),
  actualAmount: validate(greaterThanOrEqualTo(0)),
  numberOfTops: validate(greaterThanOrEqualTo(0)),
  numberOfBrackets: validate(greaterThanOrEqualTo(0)),
  services: (services) => {
    return updateArray(
      updateGroup<Service>({
        description: validate(required, (descriptionValue) => {
          const servicesArray = services.value
            .filter((as) => as.description)
            .map((as) => as.description.toLocaleLowerCase());
          if (
            descriptionValue
              ? servicesArray.filter(
                  (description) =>
                    description.toLocaleLowerCase() === descriptionValue.toLocaleLowerCase()
                ).length > 1
              : false
          ) {
            return { duplicate: 'duplicate value' };
          }
          return {};
        })
      })
    )(services)
  },
  areaSelections: (areaSelections) => {
    return updateArray(
      updateGroup<AreaSelection>({
        area: validate(required, (areaValue) => {
          const areas = areaSelections.value
            .filter((as) => as.area)
            .map((as) => as.area.toLocaleLowerCase());
          if (
            areaValue
              ? areas.filter(
                  (area) =>
                    area.toLocaleLowerCase() === areaValue.toLocaleLowerCase()
                ).length > 1
              : false
          ) {
            return { duplicate: 'duplicate value' };
          }
          return {};
        }),
        product: validate(required),
        activities: updateArray(
          updateGroup<Activity>({
            name: validate(required),
            date: validate(required),
            status: validate(required),
          })
        ),
      })
    )(areaSelections);
  },
});

export const orderReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.orderForm,
  validateOrderForm
);
