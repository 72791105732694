import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthStoreSelectors, RootStoreState } from './../root-store';

@Injectable({
  providedIn: 'root',
})
export class HasRoleGuard {
  constructor(private store: Store<RootStoreState.State>) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let isAuthenticated: boolean;
    let userRole: string;
    this.store
      .select(AuthStoreSelectors.getAuthFeatureState)
      .subscribe((state) => {
        isAuthenticated = state.isAuthenticated;
        userRole = state.user?.role;
      });
    return isAuthenticated && userRole === route.data['role'];
  }
}
