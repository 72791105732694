import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Order } from '@surface-elements/orders/domain';
import { environment } from '@surface-elements/shared/environments';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderDataService {
  private ordersUrl = `${environment.apiUrl}/orders`;

  constructor(private http: HttpClient) {}

  getOrders(): Observable<Order[]> {
    return this.http.get<Order[]>(this.ordersUrl);
  }

  createOrder(payload: Order): Observable<Order> {
    return this.http
      .post<Order>(this.ordersUrl, payload)
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateOrder(payload: Order, id: string): Observable<Order> {
    return this.http.patch<Order>(`${this.ordersUrl}/${id}`, payload);
  }

  updateOrdersInvoiceDate(invoiceDate: string, orderIds: string[]): Observable<{ invoiceDate: string; orderIds: string[] }> {
    return this.http.patch<{ invoiceDate: string; orderIds: string[] }>(
      `${this.ordersUrl}/post-invoice`,
      { invoiceDate, orderIds }
    );
  }
}
