import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { SearchService } from '../search.service';
import {
  AccountSearchResult,
  ContactSearchResult,
  JobSearchResult,
  OrderSearchResult,
  SearchResult,
} from '../searchResult';

@Component({
  selector: 'app-widget-search-bar',
  templateUrl: './widget-search-bar.component.html',
  styleUrls: ['./widget-search-bar.component.css'],
})
export class WidgetSearchBarComponent implements OnInit {
  f = new UntypedFormGroup({
    searchInput: new UntypedFormControl(''),
  });
  term$ = new Subject<string>();
  searchResults$: Observable<SearchResult>;
  accountResults: AccountSearchResult[];
  jobResults: JobSearchResult[];
  orderResults: OrderSearchResult[];
  contactResults: ContactSearchResult[];
  windowOpen$: Observable<boolean>;

  constructor(private searchService: SearchService, private router: Router) {}

  ngOnInit(): void {
    this.searchResults$ = this.term$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => {
        return term.length === 0
          ? of({ accounts: [], jobs: [], orders: [], contacts: [] })
          : this.searchService.search(term);
      })
    );

    this.searchResults$.subscribe((results) => {
      this.accountResults = results.accounts;
      this.jobResults = results.jobs;
      this.orderResults = results.orders;
      this.contactResults = results.contacts;
    });

    this.windowOpen$ = this.searchResults$.pipe(
      map((searchResults: SearchResult) => {
        return searchResults.accounts.length > 0 ||
          searchResults.jobs.length > 0 ||
          searchResults.orders.length > 0 ||
          searchResults.contacts.length > 0
          ? true
          : false;
      })
    );
  }

  onAccountClick(accountId: string): void {
    this.router.navigate(['/accounts', accountId]);
    this.f.reset();
  }

  onJobClick(jobNumber: string): void {
    this.router.navigate(['/jobs', jobNumber]);
    this.f.reset();
  }

  onOrderClick(orderId: string): void {
    this.router.navigate(['/orders', orderId]);
    this.f.reset();
  }

  onContactClick(contactId: string): void {
    this.router.navigate(['/contacts', contactId]);
    this.f.reset();
  }

  onSearch(): void {
    if (this.f.get('searchInput').value) {
      // TODO search results page
    } else {
      this.term$.next('');
    }
  }

  onBlur(): void {
    this.term$.next('');
  }

  onKeyUp(): void {
    this.term$.next(this.f.get('searchInput').value);
  }
}
