import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReportConfig, ReportUserDefinedField } from '@surface-elements/reports/domain';
import { environment } from '@surface-elements/shared/environments';

@Injectable({
  providedIn: 'root',
})
export class SettingsReportsService {
  private reportsUrl = `${environment.apiUrl}/settings/reports`;
  private reportsConfigUrl = 'config';
  private reportsUdfUrl = 'udfs';

  constructor(private http: HttpClient) {}

  getAllReportsConfig(): Observable<ReportConfig[]> {
    return this.http.get<ReportConfig[]>(
      `${this.reportsUrl}/${this.reportsConfigUrl}`
    );
  }

  updateReportsConfig(
    payload: ReportConfig,
    reportId: string
  ): Observable<ReportConfig> {
    return this.http
      .patch<ReportConfig>(
        `${this.reportsUrl}/${this.reportsConfigUrl}/${reportId}/udfConfigs`,
        payload
      )
      .pipe(catchError((error: string) => throwError(error)));
  }

  deleteReportsConfig(payload: string): Observable<ReportConfig> {
    return this.http
      .delete<ReportConfig>(`${this.reportsUrl}/${this.reportsConfigUrl}/${payload}`)
      .pipe(catchError((error: string) => throwError(error)));
  }

  getAllReportUserDefinedFields(): Observable<ReportUserDefinedField[]> {
    return this.http.get<ReportUserDefinedField[]>(
      `${this.reportsUrl}/${this.reportsUdfUrl}`
    );
  }

  deleteReportUserDefinedField(
    payload: string
  ): Observable<ReportUserDefinedField> {
    return this.http
      .delete<ReportUserDefinedField>(`${this.reportsUrl}/${this.reportsUdfUrl}/${payload}`)
      .pipe(catchError((error: string) => throwError(error)));
  }
}
