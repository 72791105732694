import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Account } from '@surface-elements/accounts/domain';
import { AccountFormValue } from './account.state';

// load accounts
export const loadAccounts = createAction('[ACCOUNT] Load Accounts');

export const loadAccountsSuccess = createAction(
  '[ACCOUNT] Load Accounts Success',
  props<{ accounts: Account[] }>()
);

export const loadAccountsFailure = createAction(
  '[ACCOUNT] Load Accounts Fail',
  props<{ error: string }>()
);

// create account
export const createAccount = createAction(
  '[ACCOUNT] Create account',
  props<{ account: Account }>()
);

export const createAccountFail = createAction(
  '[ACCOUNT] Create account Fail',
  props<{ error: string }>()
);

export const createAccountSuccess = createAction(
  '[ACCOUNT] Create account Success',
  props<Account>()
);

// delete account
export const deleteAccount = createAction(
  '[ACCOUNT] Delete account',
  props<{ account: Account }>()
);

export const deleteAccountFail = createAction(
  '[ACCOUNT] Delete account Fail',
  props<{ error: string }>()
);

export const deleteAccountSuccess = createAction(
  '[ACCOUNT] Delete account Success',
  props<{ id: string }>()
);

// update account
export const updateAccount = createAction(
  '[ACCOUNT] Update Account',
  props<{ account: Account; id: string }>()
);

export const updateAccountFail = createAction(
  '[ACCOUNT] Update Account Fail',
  props<{ error: string }>()
);

export const updateAccountSuccess = createAction(
  '[ACCOUNT] Update Account Success',
  props<Update<Account>>()
);

// current account
export const setCurrentAccount = createAction(
  '[ACCOUNT] Set Current Account',
  props<{ currentAccountNumber: string | null }>()
);

export const selectAccountIds = createAction(
  '[ACCOUNT] Select Account Ids',
  props<{ selectedIds: string[] | null }>()
);

export const clearCurrentAccount = createAction(
  '[ACCOUNT] Clear Current Account'
);

export const clearSelectedIds = createAction('[ACCOUNT] Clear Selected Ids');

export const SetEditingValueAccountAction = createAction(
  '[ACCOUNT FORM] Set editing value',
  props<{ editValue: AccountFormValue }>()
);

export const resetMyForm = createAction('[ACCOUNT FORM] Reset form');
