import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';

import { jobAdapter, JobFormValue, State } from './job.state';
import * as JobReducer from './job.reducer';

const getJobFeatureState = createFeatureSelector<State>('jobs');

export const selectAllEntities = createSelector(
  getJobFeatureState,
  JobReducer.selectEntities
);

export const selectEntityById = createSelector(
  selectAllEntities,
  (entities, props) => entities[props.id]
);

export const getJobs = createSelector(
  getJobFeatureState,
  jobAdapter.getSelectors().selectAll
);

export const getJobNumbers = createSelector(
  getJobFeatureState,
  (state: State) => state.ids
);

export const getCurrentJobNumber = createSelector(
  getJobFeatureState,
  (state: State) => state.selectedJobNumber
);

export const getCurrentJob = createSelector(
  getJobFeatureState,
  getCurrentJobNumber,
  (state: State) => state.entities[state.selectedJobNumber]
);

export const getSelectedJobNumbers = createSelector(
  getJobFeatureState,
  (state: State) => state.selectedJobNumbers
);

export const getSelectedJobs = createSelector(
  getJobs,
  getSelectedJobNumbers,
  (jobs, jobNumbers) =>
    jobs.filter((job) => jobNumbers.indexOf(job.jobNumber) > -1)
);

export const getError = createSelector(
  getJobFeatureState,
  (state) => state.error
);

export const getJobByID = (jobId: string) =>
  createSelector(getJobs, (jobs) => {
    const job = jobs.filter((job) => job._id === jobId);
    return job[0];
  });

export const getJobContacts = (jobId: string) =>
  createSelector(getJobByID(jobId), (job) => job.contacts);

export const getJobForm = createSelector(
  getJobFeatureState,
  (state: State) => state.jobForm
);

export const selectJobFormJobName = createSelector(
  getJobForm,
  (jobForm: FormGroupState<JobFormValue>) => jobForm.controls.jobName
);

export const getJobNames = createSelector(
  getJobs,
  (jobs) => {
    return jobs.map((job) => job.jobName);
  }
);
