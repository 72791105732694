import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedUiSearchModule } from '@surface-elements/shared/ui-search'
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, RouterModule, NgbModule, SharedUiSearchModule],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
  providers: [],
})
export class MenuModule {}
