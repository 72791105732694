import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SelectionDataService } from '../selection-data.service';
import { ApiError } from '@surface-elements/shared/domain';
import {
  SelectionOptionColors,
  SelectionOptionProducts,
  SelectionOptionProfiles,
  SelectionOptionSplashes,
  SelectionOptionThicknesses,
  SelectionOptionInstallerCrews,
} from '@surface-elements/shared/shared-ui';
import * as selectionActions from './selection.actions';

@Injectable()
export class SelectionEffects {
  constructor(
    private actions$: Actions,
    private selectionDataService: SelectionDataService
  ) {}

  loadSelectionOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.loadSelectionOptions),
      mergeMap(() =>
        this.selectionDataService.getAllSelections().pipe(
          map((selectionValues) =>
            selectionActions.loadSelectionOptionsSuccess({
              selectionOptions: selectionValues.selections,
            })
          ),
          catchError((error) =>
            of(selectionActions.loadSelectionOptionsFailure({ error }))
          )
        )
      )
    );
  });

  loadSelectionColors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.loadColorSelections),
      mergeMap(() =>
        this.selectionDataService.getColorSelections().pipe(
          map((colors) =>
            selectionActions.loadColorSelectionsSuccess({
              selectionOptionColors: colors,
            })
          ),
          catchError((error) =>
            of(selectionActions.loadColorSelectionsFailure({ error }))
          )
        )
      )
    );
  });

  createSelectionColor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.createColor),
      mergeMap((payload) => {
        return this.selectionDataService
          .createColorSelection(payload.color)
          .pipe(
            map((data) => {
              return selectionActions.createColorSuccess(data);
            }),
            catchError((error: ApiError) => {
              return of(
                selectionActions.createColorFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  updateSelectionColors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.updateColor),
      mergeMap(({ color, id }) =>
        this.selectionDataService.updateColorSelection(color, id).pipe(
          map((updateColor: SelectionOptionColors) =>
            selectionActions.updateColorSuccess({
              id: updateColor._id,
              changes: updateColor,
            })
          ),
          catchError((error) => of(selectionActions.updateColorFail({ error })))
        )
      )
    );
  });

  deleteSelectionEntity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        selectionActions.deleteColor,
        selectionActions.deleteProduct,
        selectionActions.deleteProfile,
        selectionActions.deleteSplash,
        selectionActions.deleteThickness,
        selectionActions.deleteInstallerCrew
      ),
      mergeMap((payload) => {
        return this.selectionDataService
          .deleteSelectionsEntity(payload.id, payload.entity)
          .pipe(
            map(() => {
              switch (payload.entity) {
                case 'colors':
                  return selectionActions.deleteColorSuccess({
                    id: payload.id,
                  });
                case 'products':
                  return selectionActions.deleteProductSuccess({
                    id: payload.id,
                  });
                case 'profiles':
                  return selectionActions.deleteProfileSuccess({
                    id: payload.id,
                  });
                case 'splashes':
                  return selectionActions.deleteSplashSuccess({
                    id: payload.id,
                  });
                case 'thicknesses':
                  return selectionActions.deleteThicknessSuccess({
                    id: payload.id,
                  });
                case 'installer_crews':
                  return selectionActions.deleteInstallerCrewSuccess({
                    id: payload.id,
                  });
                default:
                  throw new Error('Unable to update entity deletion');
              }
            }),
            catchError((error: ApiError) => {
              switch (payload.entity) {
                case 'colors':
                  return of(
                    selectionActions.deleteColorFail({
                      error: error.error.error.message,
                    })
                  );
                case 'products':
                  return of(
                    selectionActions.deleteProductFail({
                      error: error.error.error.message,
                    })
                  );
                case 'profiles':
                  return of(
                    selectionActions.deleteProfileFail({
                      error: error.error.error.message,
                    })
                  );
                case 'splashes':
                  return of(
                    selectionActions.deleteSplashFail({
                      error: error.error.error.message,
                    })
                  );
                case 'thicknesses':
                  return of(
                    selectionActions.deleteThicknessFail({
                      error: error.error.error.message,
                    })
                  );
                case 'installer_crews':
                  return of(
                    selectionActions.deleteInstallerCrewFail({
                      error: error.error.error.message,
                    })
                  );
                default:
                  throw new Error(
                    'Unable to process entity deletion error message'
                  );
              }
            })
          );
      })
    );
  });

  loadSelectionProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.loadProductSelections),
      mergeMap(() =>
        this.selectionDataService.getProductSelections().pipe(
          map((products) =>
            selectionActions.loadProductSelectionsSuccess({
              selectionOptionProducts: products,
            })
          ),
          catchError((error) =>
            of(selectionActions.loadProductSelectionsFailure({ error }))
          )
        )
      )
    );
  });

  createSelectionProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.createProduct),
      mergeMap((payload) => {
        return this.selectionDataService
          .createProductSelection(payload.product)
          .pipe(
            map((data) => {
              return selectionActions.createProductSuccess(data);
            }),
            catchError((error: ApiError) => {
              return of(
                selectionActions.createProductFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  updateSelectionProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.updateProduct),
      mergeMap(({ product, id }) =>
        this.selectionDataService.updateProductSelection(product, id).pipe(
          map((updateProduct: SelectionOptionProducts) =>
            selectionActions.updateProductSuccess({
              id: updateProduct._id,
              changes: updateProduct,
            })
          ),
          catchError((error) =>
            of(selectionActions.updateProductFail({ error }))
          )
        )
      )
    );
  });

  loadSelectionProfiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.loadProfileSelections),
      mergeMap(() =>
        this.selectionDataService.getProfileSelections().pipe(
          map((profiles) =>
            selectionActions.loadProfileSelectionsSuccess({
              selectionOptionProfiles: profiles,
            })
          ),
          catchError((error) =>
            of(selectionActions.loadProfileSelectionsFailure({ error }))
          )
        )
      )
    );
  });

  createSelectionProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.createProfile),
      mergeMap((payload) => {
        return this.selectionDataService
          .createProfileSelection(payload.profile)
          .pipe(
            map((data) => {
              return selectionActions.createProfileSuccess(data);
            }),
            catchError((error: ApiError) => {
              return of(
                selectionActions.createProfileFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  updateSelectionProfiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.updateProfile),
      mergeMap(({ profile, id }) =>
        this.selectionDataService.updateProfileSelection(profile, id).pipe(
          map((updateProfile: SelectionOptionProfiles) =>
            selectionActions.updateProfileSuccess({
              id: updateProfile._id,
              changes: updateProfile,
            })
          ),
          catchError((error) =>
            of(selectionActions.updateProfileFail({ error }))
          )
        )
      )
    );
  });

  loadSelectionSplashes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.loadSplashSelections),
      mergeMap(() =>
        this.selectionDataService.getSplashSelections().pipe(
          map((splashes) =>
            selectionActions.loadSplashSelectionsSuccess({
              selectionOptionSplashes: splashes,
            })
          ),
          catchError((error) =>
            of(selectionActions.loadSplashSelectionsFailure({ error }))
          )
        )
      )
    );
  });

  createSelectionSplash$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.createSplash),
      mergeMap((payload) => {
        return this.selectionDataService
          .createSplashSelection(payload.splash)
          .pipe(
            map((data) => {
              return selectionActions.createSplashSuccess(data);
            }),
            catchError((error: ApiError) => {
              return of(
                selectionActions.createSplashFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  updateSelectionSplashes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.updateSplash),
      mergeMap(({ splash, id }) =>
        this.selectionDataService.updateSplashSelection(splash, id).pipe(
          map((updateSplash: SelectionOptionSplashes) =>
            selectionActions.updateSplashSuccess({
              id: updateSplash._id,
              changes: updateSplash,
            })
          ),
          catchError((error) =>
            of(selectionActions.updateSplashFail({ error }))
          )
        )
      )
    );
  });

  loadSelectionThicknesses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.loadThicknessSelections),
      mergeMap(() =>
        this.selectionDataService.getThicknessSelections().pipe(
          map((thicknesses) =>
            selectionActions.loadThicknessSelectionsSuccess({
              selectionOptionThicknesses: thicknesses,
            })
          ),
          catchError((error) =>
            of(selectionActions.loadThicknessSelectionsFailure({ error }))
          )
        )
      )
    );
  });

  createSelectionThickness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.createThickness),
      mergeMap((payload) => {
        return this.selectionDataService
          .createThicknessSelection(payload.thickness)
          .pipe(
            map((data) => {
              return selectionActions.createThicknessSuccess(data);
            }),
            catchError((error: ApiError) => {
              return of(
                selectionActions.createThicknessFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  updateSelectionThicknesses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.updateThickness),
      mergeMap(({ thickness, id }) =>
        this.selectionDataService.updateThicknessSelection(thickness, id).pipe(
          map((updateThickness: SelectionOptionThicknesses) =>
            selectionActions.updateThicknessSuccess({
              id: updateThickness._id,
              changes: updateThickness,
            })
          ),
          catchError((error) =>
            of(selectionActions.updateThicknessFail({ error }))
          )
        )
      )
    );
  });

  loadSelectionInstallerCrews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.loadInstallerCrewSelections),
      mergeMap(() =>
        this.selectionDataService.getInstallerCrewSelections().pipe(
          map((installerCrews) =>
            selectionActions.loadInstallerCrewSelectionsSuccess({
              selectionOptionInstallerCrews: installerCrews,
            })
          ),
          catchError((error) =>
            of(selectionActions.loadInstallerCrewSelectionsFailure({ error }))
          )
        )
      )
    );
  });

  createSelectionInstallerCrews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.createInstallerCrew),
      mergeMap((payload) => {
        return this.selectionDataService
          .createInstallerCrewSelection(payload.crew)
          .pipe(
            map((data) => {
              return selectionActions.createInstallerCrewSuccess(data);
            }),
            catchError((error: ApiError) => {
              return of(
                selectionActions.createInstallerCrewFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  updateSelectionInstallerCrews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(selectionActions.updateInstallerCrew),
      mergeMap(({ crew, id }) =>
        this.selectionDataService.updateInstallerCrewSelection(crew, id).pipe(
          map((updateInstallCrew: SelectionOptionInstallerCrews) =>
            selectionActions.updateInstallerCrewSuccess({
              id: updateInstallCrew._id,
              changes: updateInstallCrew,
            })
          ),
          catchError((error) =>
            of(selectionActions.updateInstallerCrewFail({ error }))
          )
        )
      )
    );
  });
}
