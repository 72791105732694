import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of, timer } from 'rxjs';

/* NgRx */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as authActions from './auth.actions';

import { AuthService } from '../../auth/auth.service';
import { User } from '@surface-elements/shared/shared-ui';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.login),
      mergeMap((payload) =>
        this.authService
          .logIn(payload.emailAddress || '', payload.password || '')
          .pipe(
            map((user) => {
              return authActions.loginSuccess({ user, redirect: true });
            }),
            catchError((error) => {
              console.log(error);
              return of(authActions.loginFailure(error));
            })
          )
      )
    );
  });

  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.autoLogin),
      map(() => {
        const authToken = localStorage.getItem('token');

        // If no token use logout as a redirect to login page
        if (!authToken) {
          return authActions.logOut();
        }

        // If token exists but is expired use logout to clear local storage and redirect to login page
        const expireDuration = Math.round(
          new Date(+localStorage.getItem('tokenExpiresAt')).getTime() -
            new Date().getTime()
        );
        if (expireDuration < 0) {
          return authActions.logOut();
        }

        const user: User = {
          _id: localStorage.getItem('_id'),
          authToken: localStorage.getItem('token'),
          authTokenExpiresAt: localStorage.getItem('tokenExpiresAt'),
          emailAddress: localStorage.getItem('email'),
          firstName: localStorage.getItem('first'),
          lastName: localStorage.getItem('last'),
          role: localStorage.getItem('role'),
        };

        return authActions.loginSuccess({ user, redirect: false });
      })
    );
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.loginSuccess),
      map(({ user, redirect }) => {
        const now = Math.round(new Date().getTime());
        const authExpirationMilliseconds =
          new Date(+user.authTokenExpiresAt).getTime() - now;
        localStorage.setItem('token', user.authToken || '');
        localStorage.setItem('tokenExpiresAt', user.authTokenExpiresAt || '');
        localStorage.setItem('_id', user._id || '');
        localStorage.setItem('email', user.emailAddress || '');
        localStorage.setItem('first', user.firstName || '');
        localStorage.setItem('last', user.lastName || '');
        localStorage.setItem('role', user.role || '');
        return { redirect, authExpirationMilliseconds };
      }),
      map(({ redirect, authExpirationMilliseconds }) => {
        if (redirect) {
          this.router.navigate(['/']);
        }
        return authActions.autoLogout({
          expireMilliseconds: authExpirationMilliseconds,
        });
      })
    );
  });

  autoLogOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.autoLogout),
      switchMap((action) => timer(action.expireMilliseconds)),
      map(() => authActions.logOut())
    );
  });

  logOff$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.logOut),
        tap(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiresAt');
          localStorage.removeItem('_id');
          localStorage.removeItem('email');
          localStorage.removeItem('first');
          localStorage.removeItem('last');
          localStorage.removeItem('role');
          this.router.navigateByUrl('/login');
        })
      );
    },
    { dispatch: false }
  );

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.resetPassword),
      mergeMap((payload) =>
        this.authService.resetPassword(payload.userName).pipe(
          map(() => {
            return authActions.resetPasswordSuccess();
          }),
          catchError((error) => {
            return of(authActions.resetPasswordFailure({ error }));
          })
        )
      )
    );
  });

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.updatePassword),
      mergeMap((payload) =>
        this.authService
          .updatePassword(payload.userId, payload.token, payload.password)
          .pipe(
            map(() => {
              return authActions.updatePasswordSuccess();
            }),
            catchError((error) => {
              return of(authActions.updatePasswordFailure({ error }));
            })
          )
      )
    );
  });
}
