import { SharedDataAccessModule } from './shared-data-access.module';

export class EnsureModuleLoadedOnceGuard {
  constructor(targetModule: SharedDataAccessModule) {
    if (targetModule) {
      throw new Error(`${targetModule.constructor.name} has already been loaded.
        Import this module in the AppModule only.`);
    }
  }
}
