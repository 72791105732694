import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Account } from '@surface-elements/accounts/domain';
import { Address, Email, Phone } from '@surface-elements/shared/shared-ui';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';

export interface AccountFormValue {
  _id: string;
  name: string;
  website: string;
  address: Address;
  phone: Phone;
  email: Email;
  note: string;
}

export const ACCOUNT_FORM_ID = 'accountForm';

export const INITIAL_ACCOUNT_FORM_STATE =
  createFormGroupState<AccountFormValue>(ACCOUNT_FORM_ID, {
    _id: null,
    name: '',
    website: '',
    address: {
      addressType: 'Business',
      street: '',
      city: '',
      state: '',
      zip: ''
    },
    phone: {
      type: 'Main',
      number: ''
    },
    email: {
      type: 'Account',
      emailAddress: ''
    },
    note: ''
  })
export interface State extends EntityState<Account> {
  selectedAccountId: string | null;
  selectedIds: string[] | null;
  accountForm: FormGroupState<AccountFormValue>;
  error: string;
}

export const accountAdapter: EntityAdapter<Account> =
  createEntityAdapter<Account>({
    sortComparer: sortByAccountName,
    selectId: (account: Account) => account._id,
  });

function sortByAccountName(an1: Account, an2: Account): number {
  return an1.name.toLowerCase() < an2.name.toLowerCase()
    ? -1
    : an1.name.toLowerCase() > an2.name.toLowerCase()
    ? 1
    : 0;
}

export const defaultAccount: State = {
  ids: [],
  entities: {},
  selectedAccountId: null,
  selectedIds: [],
  accountForm: INITIAL_ACCOUNT_FORM_STATE,
  error: '',
};

export const initialState = accountAdapter.getInitialState(defaultAccount);
