import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportService {
  defaultOptions: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

  exportJsonTable(dataTable: any[], fileName: string, sheet: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet<any>(dataTable);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheet);
    XLSX.writeFile(wb, fileName, this.defaultOptions);
  }

}
