enum responseFormat {
  none = 'None',
  yesNo = 'Y/N',
  underline = 'Underline',
  checkbox = 'Checkbox',
  custom = 'Custom',
  date = 'Date',
}

export enum alignPosition {
  left = 'Left',
  center = 'Center',
  right = 'Right',
}

export interface ReportUserDefinedField {
  _id?: string;
  displayText: string;
  responseFormat: responseFormat;
}

export interface UdfConfig {
  udf: ReportUserDefinedField;
  config: {
    print: boolean;
    location: {
      preciseLocationEnabled: boolean;
      preciseLocation?: {
        x: number;
        y: number;
      };
      defaultLocation?: {
        sortPosition: number;
        alignPosition: alignPosition;
      };
    };
    text?: {
      defaultUdfFont: string;
      defaultUdfFontSize: number;
      defaultResponseFont: string;
      defaultResponseFontSize: number;
    };
  };
}

export interface ReportConfig {
  _id?: string;
  reportName: string;
  systemReport: boolean;
  udfConfigs: UdfConfig[];
}
