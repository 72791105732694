import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

/* NgRx */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as contactActions from './contact.actions';
import { JobStoreActions, JobDataService } from '@surface-elements/jobs/data-access';

import { ApiError } from '@surface-elements/shared/domain';
import { AccountDataService } from '@surface-elements/accounts/data-access';
import { ContactDataService } from '../contact-data.service';
import { AccountStoreActions } from '@surface-elements/accounts/data-access';

@Injectable()
export class ContactEffects {
  constructor(
    private actions$: Actions,
    private contactDataService: ContactDataService,
    private accountDataService: AccountDataService,
    private jobDataService: JobDataService,
    private router: Router,
  ) {}

  loadContacts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.loadContacts),
      mergeMap(() =>
        this.contactDataService.getContacts().pipe(
          map((contacts) => contactActions.loadContactsSuccess({ contacts })),
          catchError((error) =>
            of(contactActions.loadContactsFailure({ error }))
          )
        )
      )
    );
  });

  createContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.createContact),
      mergeMap((payload) => {
        return this.contactDataService.createContact(payload.contact).pipe(
          map((data) => {
            return contactActions.createContactSuccess(data);
          }),
          catchError((error: ApiError) => {
            return of(
              contactActions.createContactFail({
                error: error.error.error.message,
              })
            );
          })
        );
      })
    );
  });

  createAccountContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.createAccountContact),
      mergeMap((payload) => {
        return this.accountDataService
          .createAccountContact(payload.contact, payload.accountId)
          .pipe(
            map((data) => {
              return contactActions.createAccountContactSuccess(data);
            }),
            catchError((error: ApiError) => {
              return of(
                contactActions.createAccountContactFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  createJobContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.createJobContact),
      mergeMap((payload) => {
        return this.jobDataService
          .createJobContact(payload.contact, payload.jobId)
          .pipe(
            map((data) => {
              return contactActions.createJobContactSuccess(data);
            }),
            catchError((error: ApiError) => {
              return of(
                contactActions.createJobContactFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });
  updateContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.updateContact),
      mergeMap(({ contact, id }) =>
        this.contactDataService.updateContact(contact, id).pipe(
          map(() =>
            contactActions.updateContactSuccess({ id, changes: contact })
          ),
          catchError((error) =>
            of(contactActions.updateContactFailure({ error }))
          )
        )
      )
    );
  });
  deleteContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.deleteContact),
      mergeMap((payload) => {
        return this.contactDataService.deleteContact(payload.contact).pipe(
          map(() => {
            return contactActions.deleteContactSuccess({
              id: payload.contact._id,
            });
          }),
          catchError((error: ApiError) => {
            return of(
              contactActions.deleteContactFail({
                error: error.error.error.message,
              })
            );
          })
        );
      })
    );
  });

  handleDeleteContact$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(contactActions.deleteContactSuccess),
        tap(() => this.router.navigate(['/contacts']))
      );
    },
    { dispatch: false }
  );

  linkJobContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.linkJobContact),
      mergeMap((payload) => {
        return this.contactDataService
          .linkJobContact(payload.contact, payload.job)
          .pipe(
            switchMap(() => {
              return [
                JobStoreActions.updateJobSuccess({
                  id: payload.job._id,
                  changes: payload.job,
                }),
                contactActions.linkJobContactSuccess({
                  id: payload.contact._id,
                  changes: payload.contact,
                })
              ]
            }),
            catchError((error: ApiError) => {
              return of(
                contactActions.linkJobContactFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  unlinkJobContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.unlinkJobContact),
      mergeMap((payload) => {
        return this.contactDataService
          .unlinkJobContact(payload.contact, payload.job)
          .pipe(
            switchMap(() => {
              return [
                JobStoreActions.updateJobSuccess({
                  id: payload.job._id,
                  changes: payload.job,
                }),
                contactActions.unlinkJobContactSuccess({
                  id: payload.contact._id,
                  changes: payload.contact,
                })
              ]
            }),
            catchError((error: ApiError) => {
              return of(
                contactActions.unlinkJobContactFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  linkAccountContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.linkAccountContact),
      mergeMap((payload) => {
        return this.contactDataService
          .linkAccountContact(payload.contact, payload.account)
          .pipe(
            switchMap(() => {
              return [
                AccountStoreActions.updateAccountSuccess({
                  id: payload.account._id,
                  changes: payload.account,
                }),
                contactActions.linkAccountContactSuccess({
                  id: payload.contact._id,
                  changes: payload.contact,
                })
              ]
            }),
            catchError((error: ApiError) => {
              return of(
                contactActions.linkAccountContactFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });

  unlinkAccountContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.unlinkAccountContact),
      mergeMap((payload) => {
        return this.contactDataService
          .unlinkAccountContact(payload.contact, payload.account)
          .pipe(
            switchMap(() => {
              return [
                AccountStoreActions.updateAccountSuccess({
                  id: payload.account._id,
                  changes: payload.account,
                }),
                contactActions.unlinkAccountContactSuccess({
                  id: payload.contact._id,
                  changes: payload.contact,
                })
              ]
            }),
            catchError((error: ApiError) => {
              return of(
                contactActions.unlinkAccountContactFail({
                  error: error.error.error.message,
                })
              );
            })
          );
      })
    );
  });
}
