export class User {
  emailAddress?: string | null;
  password?: string | null;
  _id?: string | null;
  role?: string | null;
  authToken?: string | null;
  authTokenExpiresAt?: string | null;
  lastName?: string;
  firstName?: string;
}
