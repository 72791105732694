<div class="appContainer">
  <div class="appHeader">
    <app-navbar></app-navbar>
  </div>
  <ngx-spinner
    bdColor="rgba(0,0,0,0.7)"
    size="medium"
    color="#fff"
    type="ball-pulse"
    [fullScreen]="true"
  >
    <p style="color: white">Loading...</p>
  </ngx-spinner>
  <div class="appContent scrollbarYonly">
    <router-outlet></router-outlet>
  </div>
  <div class="appFooter bg-primary">
    ©2022 SEND SERV LLC. ALL RIGHTS RESERVED.
  </div>
</div>
