import { createReducer, on } from '@ngrx/store';

import {
  State,
  initialState,
  SelectionColorFormValue,
  SelectionProductFormValue,
  SelectionProfileFormValue,
  SelectionSplashFormValue,
  SelectionThicknessFormValue,
  SelectionInstallerCrewFormValue,
  selectionColorAdapter,
  selectionProductAdapter,
  selectionProfileAdapter,
  selectionSplashAdapter,
  selectionThicknessAdapter,
  selectionInstallerCrewAdapter,
  COLOR_FORM_ID,
  PRODUCT_FORM_ID,
  PROFILE_FORM_ID,
  SPLASH_FORM_ID,
  THICKNESS_FORM_ID,
  INSTALLER_CREW_FORM_ID,
} from './selection.state';

import * as selectionActions from './selection.actions';
import {
  createFormGroupState,
  onNgrxForms,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

let reducer = createReducer<State>(
  initialState,
  onNgrxForms(),
  on(
    selectionActions.SetEditingValueSelectionColorAction,
    (state, action): State => {
      return {
        ...state,
        colorForm: createFormGroupState<SelectionColorFormValue>(
          COLOR_FORM_ID,
          action.editValue
        ),
      };
    }
  ),
  on(
    selectionActions.SetEditingValueSelectionProductAction,
    (state, action): State => {
      return {
        ...state,
        productForm: createFormGroupState<SelectionProductFormValue>(
          PRODUCT_FORM_ID,
          action.editValue
        ),
      };
    }
  ),
  on(
    selectionActions.SetEditingValueSelectionProfileAction,
    (state, action): State => {
      return {
        ...state,
        profileForm: createFormGroupState<SelectionProfileFormValue>(
          PROFILE_FORM_ID,
          action.editValue
        ),
      };
    }
  ),
  on(
    selectionActions.SetEditingValueSelectionSplashAction,
    (state, action): State => {
      return {
        ...state,
        splashForm: createFormGroupState<SelectionSplashFormValue>(
          SPLASH_FORM_ID,
          action.editValue
        ),
      };
    }
  ),
  on(
    selectionActions.SetEditingValueSelectionThicknessAction,
    (state, action): State => {
      return {
        ...state,
        thicknessForm: createFormGroupState<SelectionThicknessFormValue>(
          THICKNESS_FORM_ID,
          action.editValue
        ),
      };
    }
  ),
  on(
    selectionActions.SetEditingValueSelectionInstallerCrewAction,
    (state, action): State => {
      return {
        ...state,
        installerCrewForm:
          createFormGroupState<SelectionInstallerCrewFormValue>(
            INSTALLER_CREW_FORM_ID,
            action.editValue
          ),
      };
    }
  ),
  on(selectionActions.loadSelectionOptionsSuccess, (state, action): State => {
    return {
      ...state,
      selectionOptions: action.selectionOptions,
    };
  }),
  on(selectionActions.loadSelectionOptionsFailure, (state): State => {
    return {
      ...state,
      error: '',
    };
  }),
  on(selectionActions.loadColorSelectionsSuccess, (state, action): State => {
    const colorsState = selectionColorAdapter.setAll(
      action.selectionOptionColors,
      {
        ...state.colors,
        error: '',
      }
    );
    return {
      ...state,
      colors: {
        entities: colorsState.entities,
        ids: colorsState.ids,
        error: colorsState.error,
      },
    };
  }),
  on(selectionActions.loadColorSelectionsFailure, (state, action): State => {
    return {
      ...state,
      colors: {
        ...state.colors,
        entities: {},
        error: action.error,
      },
    };
  }),
  on(selectionActions.createColorSuccess, (state, action): State => {
    const colorState = selectionColorAdapter.addOne(action, state.colors);
    return {
      ...state,
      colors: colorState,
    };
  }),
  on(selectionActions.createColorFail, (state, action): State => {
    return {
      ...state,
      colors: {
        ...state.colors,
        error: action.error,
      },
    };
  }),
  on(selectionActions.deleteColorSuccess, (state, { id }): State => {
    const colorsState = selectionColorAdapter.removeOne(id, state.colors);
    return {
      ...state,
      colors: colorsState,
    };
  }),
  on(selectionActions.deleteColorFail, (state, action): State => {
    return {
      ...state,
      colors: {
        ...state.colors,
        error: action.error,
      },
    };
  }),
  on(selectionActions.updateColorSuccess, (state, action): State => {
    const colorsState = selectionColorAdapter.updateOne(action, state.colors);
    return {
      ...state,
      colors: colorsState,
    };
  }),
  on(selectionActions.updateColorFail, (state, action): State => {
    return {
      ...state,
      colors: {
        ...state.colors,
        error: action.error,
      },
    };
  }),
  on(selectionActions.loadProductSelectionsSuccess, (state, action): State => {
    const productsState = selectionProductAdapter.setAll(
      action.selectionOptionProducts,
      {
        ...state.products,
        error: '',
      }
    );
    return {
      ...state,
      products: {
        entities: productsState.entities,
        ids: productsState.ids,
        error: productsState.error,
      },
    };
  }),
  on(selectionActions.loadProductSelectionsFailure, (state, action): State => {
    return {
      ...state,
      products: {
        ...state.products,
        entities: {},
        error: action.error,
      },
    };
  }),
  on(selectionActions.createProductSuccess, (state, action): State => {
    const productState = selectionProductAdapter.addOne(action, state.products);
    return {
      ...state,
      products: productState,
    };
  }),
  on(selectionActions.createProductFail, (state, action): State => {
    return {
      ...state,
      products: {
        ...state.products,
        error: action.error,
      },
    };
  }),
  on(selectionActions.deleteProductSuccess, (state, { id }): State => {
    const productState = selectionProductAdapter.removeOne(id, state.products);
    return {
      ...state,
      products: productState,
    };
  }),
  on(selectionActions.deleteProductFail, (state, action): State => {
    return {
      ...state,
      products: {
        ...state.products,
        error: action.error,
      },
    };
  }),
  on(selectionActions.updateProductSuccess, (state, action): State => {
    const productsState = selectionProductAdapter.updateOne(
      action,
      state.products
    );
    return {
      ...state,
      products: productsState,
    };
  }),
  on(selectionActions.updateProductFail, (state, action): State => {
    return {
      ...state,
      products: {
        ...state.products,
        error: action.error,
      },
    };
  }),
  on(selectionActions.loadProfileSelectionsSuccess, (state, action): State => {
    const profilesState = selectionProfileAdapter.setAll(
      action.selectionOptionProfiles,
      {
        ...state.profiles,
        error: '',
      }
    );
    return {
      ...state,
      profiles: {
        entities: profilesState.entities,
        ids: profilesState.ids,
        error: profilesState.error,
      },
    };
  }),
  on(selectionActions.loadProfileSelectionsFailure, (state, action): State => {
    return {
      ...state,
      profiles: {
        ...state.profiles,
        entities: {},
        error: action.error,
      },
    };
  }),
  on(selectionActions.createProfileSuccess, (state, action): State => {
    const profileState = selectionProfileAdapter.addOne(action, state.profiles);
    return {
      ...state,
      profiles: profileState,
    };
  }),
  on(selectionActions.createProfileFail, (state, action): State => {
    return {
      ...state,
      profiles: {
        ...state.profiles,
        error: action.error,
      },
    };
  }),
  on(selectionActions.deleteProfileSuccess, (state, { id }): State => {
    const profileState = selectionProfileAdapter.removeOne(id, state.profiles);
    return {
      ...state,
      profiles: profileState,
    };
  }),
  on(selectionActions.deleteProfileFail, (state, action): State => {
    return {
      ...state,
      profiles: {
        ...state.profiles,
        error: action.error,
      },
    };
  }),
  on(selectionActions.updateProfileSuccess, (state, action): State => {
    const profilesState = selectionProfileAdapter.updateOne(
      action,
      state.profiles
    );
    return {
      ...state,
      profiles: profilesState,
    };
  }),
  on(selectionActions.updateProfileFail, (state, action): State => {
    return {
      ...state,
      profiles: {
        ...state.profiles,
        error: action.error,
      },
    };
  }),
  on(selectionActions.loadSplashSelectionsSuccess, (state, action): State => {
    const splashesState = selectionSplashAdapter.setAll(
      action.selectionOptionSplashes,
      {
        ...state.splashes,
        error: '',
      }
    );
    return {
      ...state,
      splashes: {
        entities: splashesState.entities,
        ids: splashesState.ids,
        error: splashesState.error,
      },
    };
  }),
  on(selectionActions.loadSplashSelectionsFailure, (state, action): State => {
    return {
      ...state,
      splashes: {
        ...state.splashes,
        entities: {},
        error: action.error,
      },
    };
  }),
  on(selectionActions.createSplashSuccess, (state, action): State => {
    const splashState = selectionSplashAdapter.addOne(action, state.splashes);
    return {
      ...state,
      splashes: splashState,
    };
  }),
  on(selectionActions.createSplashFail, (state, action): State => {
    return {
      ...state,
      splashes: {
        ...state.splashes,
        error: action.error,
      },
    };
  }),
  on(selectionActions.deleteSplashSuccess, (state, { id }): State => {
    const splashState = selectionSplashAdapter.removeOne(id, state.splashes);
    return {
      ...state,
      splashes: splashState,
    };
  }),
  on(selectionActions.deleteSplashFail, (state, action): State => {
    return {
      ...state,
      splashes: {
        ...state.splashes,
        error: action.error,
      },
    };
  }),
  on(selectionActions.updateSplashSuccess, (state, action): State => {
    const splashesState = selectionSplashAdapter.updateOne(
      action,
      state.splashes
    );
    return {
      ...state,
      splashes: splashesState,
    };
  }),
  on(selectionActions.updateSplashFail, (state, action): State => {
    return {
      ...state,
      splashes: {
        ...state.splashes,
        error: action.error,
      },
    };
  }),
  on(
    selectionActions.loadThicknessSelectionsSuccess,
    (state, action): State => {
      const thicknessState = selectionThicknessAdapter.setAll(
        action.selectionOptionThicknesses,
        {
          ...state.thicknesses,
          error: '',
        }
      );
      return {
        ...state,
        thicknesses: {
          entities: thicknessState.entities,
          ids: thicknessState.ids,
          error: thicknessState.error,
        },
      };
    }
  ),
  on(
    selectionActions.loadThicknessSelectionsFailure,
    (state, action): State => {
      return {
        ...state,
        thicknesses: {
          ...state.thicknesses,
          entities: {},
          error: action.error,
        },
      };
    }
  ),
  on(selectionActions.createThicknessSuccess, (state, action): State => {
    const thicknessState = selectionThicknessAdapter.addOne(
      action,
      state.thicknesses
    );
    return {
      ...state,
      thicknesses: thicknessState,
    };
  }),
  on(selectionActions.createThicknessFail, (state, action): State => {
    return {
      ...state,
      thicknesses: {
        ...state.thicknesses,
        error: action.error,
      },
    };
  }),
  on(selectionActions.deleteThicknessSuccess, (state, { id }): State => {
    const thicknessState = selectionThicknessAdapter.removeOne(
      id,
      state.thicknesses
    );
    return {
      ...state,
      thicknesses: thicknessState,
    };
  }),
  on(selectionActions.deleteThicknessFail, (state, action): State => {
    return {
      ...state,
      thicknesses: {
        ...state.thicknesses,
        error: action.error,
      },
    };
  }),
  on(selectionActions.updateThicknessSuccess, (state, action): State => {
    const thicknessesState = selectionThicknessAdapter.updateOne(
      action,
      state.thicknesses
    );
    return {
      ...state,
      thicknesses: thicknessesState,
    };
  }),
  on(selectionActions.updateThicknessFail, (state, action): State => {
    return {
      ...state,
      thicknesses: {
        ...state.thicknesses,
        error: action.error,
      },
    };
  }),
  on(
    selectionActions.loadInstallerCrewSelectionsSuccess,
    (state, action): State => {
      const installerCrewState = selectionInstallerCrewAdapter.setAll(
        action.selectionOptionInstallerCrews,
        {
          ...state.installerCrews,
          error: '',
        }
      );
      return {
        ...state,
        installerCrews: {
          entities: installerCrewState.entities,
          ids: installerCrewState.ids,
          error: installerCrewState.error,
        },
      };
    }
  ),
  on(
    selectionActions.loadInstallerCrewSelectionsFailure,
    (state, action): State => {
      return {
        ...state,
        installerCrews: {
          ...state.installerCrews,
          entities: {},
          error: action.error,
        },
      };
    }
  ),
  on(selectionActions.createInstallerCrewSuccess, (state, action): State => {
    const installerCrewState = selectionInstallerCrewAdapter.addOne(
      action,
      state.installerCrews
    );
    return {
      ...state,
      installerCrews: installerCrewState,
    };
  }),
  on(selectionActions.createInstallerCrewFail, (state, action): State => {
    return {
      ...state,
      installerCrews: {
        ...state.installerCrews,
        error: action.error,
      },
    };
  }),
  on(selectionActions.deleteInstallerCrewSuccess, (state, { id }): State => {
    const installerCrewState = selectionInstallerCrewAdapter.removeOne(
      id,
      state.installerCrews
    );
    return {
      ...state,
      installerCrews: installerCrewState,
    };
  }),
  on(selectionActions.deleteInstallerCrewFail, (state, action): State => {
    return {
      ...state,
      installerCrews: {
        ...state.installerCrews,
        error: action.error,
      },
    };
  }),
  on(selectionActions.updateInstallerCrewSuccess, (state, action): State => {
    const installerCrewState = selectionInstallerCrewAdapter.updateOne(
      action,
      state.installerCrews
    );
    return {
      ...state,
      installerCrews: installerCrewState,
    };
  }),
  on(selectionActions.updateInstallerCrewFail, (state, action): State => {
    return {
      ...state,
      installerCrews: {
        ...state.installerCrews,
        error: action.error,
      },
    };
  })
);

const validateColorSelectionForm = updateGroup<SelectionColorFormValue>({
  product: validate(required),
  color: validate(required),
});

const validateProductSelectionForm = updateGroup<SelectionProductFormValue>({
  product: validate(required),
});

const validateProfileSelectionForm = updateGroup<SelectionProfileFormValue>({
  profile: validate(required),
});

const validateSplashSelectionForm = updateGroup<SelectionSplashFormValue>({
  splash: validate(required),
});

const validateThicknessSelectionForm = updateGroup<SelectionThicknessFormValue>(
  {
    thickness: validate(required),
  }
);

const validateInstallerCrewSelectionForm =
  updateGroup<SelectionInstallerCrewFormValue>({
    crew: validate(required),
  });

reducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.colorForm,
  validateColorSelectionForm
);
reducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.productForm,
  validateProductSelectionForm
);
reducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.profileForm,
  validateProfileSelectionForm
);
reducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.splashForm,
  validateSplashSelectionForm
);
reducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.thicknessForm,
  validateThicknessSelectionForm
);

export const selectionReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.installerCrewForm,
  validateInstallerCrewSelectionForm
);
