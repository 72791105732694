import { createFeatureSelector, createSelector } from '@ngrx/store';

import { orderAdapter, State } from './order.state';

const getOrderFeatureState = createFeatureSelector<State>('orders');

export const getOrders = createSelector(
  getOrderFeatureState,
  orderAdapter.getSelectors().selectAll
);

export const getCurrentOrderId = createSelector(
  getOrderFeatureState,
  (state: State) => state.selectedOrderId
);

export const getCurrentOrder = createSelector(
  getOrderFeatureState,
  getCurrentOrderId,
  (state: State) => state.entities[state.selectedOrderId]
);

export const getOrderForm = createSelector(
  getOrderFeatureState,
  (state: State) => state.orderForm
);

export const getActiveOrders = createSelector(getOrders, (orders) =>
  orders.filter((order) => order.active)
);

export const getSelectedIds = createSelector(
  getOrderFeatureState,
  (state: State) => state.selectedIds
);

export const getSelectedOrders = createSelector(
  getOrders,
  getSelectedIds,
  (orders, ids) => orders.filter((order) => ids.indexOf(order._id) > -1)
);

export const getError = createSelector(
  getOrderFeatureState,
  (state) => state.error
);
