import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';

import {
  SelectionOptionColors,
  SelectionOptionInstallerCrews,
  SelectionOptionProducts,
  SelectionOptionProfiles,
  SelectionOptionSplashes,
  SelectionOptionThicknesses,
} from '@surface-elements/shared/shared-ui';

// COLORS
export interface SelectionColorFormValue {
  _id: string;
  product: string;
  color: string;
}

export const COLOR_FORM_ID = 'colorForm';

export const initialColorFormState =
  createFormGroupState<SelectionColorFormValue>(COLOR_FORM_ID, {
    _id: null,
    product: '',
    color: '',
  });

export interface ColorState extends EntityState<SelectionOptionColors> {
  error: string;
}

export const selectionColorAdapter: EntityAdapter<SelectionOptionColors> =
  createEntityAdapter<SelectionOptionColors>({
    selectId: (colorSelectionOptions: SelectionOptionColors) =>
      colorSelectionOptions._id,
  });

export const defaultSelectionOptionColors: ColorState = {
  ids: [],
  entities: {},
  error: '',
};

export const selectionColorInitialState = selectionColorAdapter.getInitialState(
  defaultSelectionOptionColors
);

// PRODUCTS
export interface SelectionProductFormValue {
  _id: string;
  product: string;
}

export const PRODUCT_FORM_ID = 'productForm';

export const initialProductFormState =
  createFormGroupState<SelectionProductFormValue>(PRODUCT_FORM_ID, {
    _id: null,
    product: '',
  });

interface ProductsState extends EntityState<SelectionOptionProducts> {
  error: string;
}

export const selectionProductAdapter: EntityAdapter<SelectionOptionProducts> =
  createEntityAdapter<SelectionOptionProducts>({
    selectId: (productSelectionOptions: SelectionOptionProducts) =>
      productSelectionOptions._id,
  });

export const defaultSelectionOptionProducts: ProductsState = {
  ids: [],
  entities: {},
  error: '',
};

export const selectionProductInitialState =
  selectionProductAdapter.getInitialState(defaultSelectionOptionProducts);

// PROFILES
export interface SelectionProfileFormValue {
  _id: string;
  profile: string;
}

export const PROFILE_FORM_ID = 'profileForm';

export const initialProfileFormState =
  createFormGroupState<SelectionProfileFormValue>(PROFILE_FORM_ID, {
    _id: null,
    profile: '',
  });

interface ProfileState extends EntityState<SelectionOptionProfiles> {
  error: string;
}

export const selectionProfileAdapter: EntityAdapter<SelectionOptionProfiles> =
  createEntityAdapter<SelectionOptionProfiles>({
    selectId: (profileSelectionOptions: SelectionOptionProfiles) =>
      profileSelectionOptions._id,
  });

export const defaultSelectionOptionProfiles: ProfileState = {
  ids: [],
  entities: {},
  error: '',
};

export const selectionProfileInitialState =
  selectionProfileAdapter.getInitialState(defaultSelectionOptionProfiles);

// SPLASHES
export interface SelectionSplashFormValue {
  _id: string;
  splash: string;
}

export const SPLASH_FORM_ID = 'splashForm';

export const initialSplashFormState =
  createFormGroupState<SelectionSplashFormValue>(SPLASH_FORM_ID, {
    _id: null,
    splash: '',
  });

interface SplashState extends EntityState<SelectionOptionSplashes> {
  error: string;
}

export const selectionSplashAdapter: EntityAdapter<SelectionOptionSplashes> =
  createEntityAdapter<SelectionOptionSplashes>({
    selectId: (splashSelectionOptions: SelectionOptionSplashes) =>
      splashSelectionOptions._id,
  });

export const defaultSelectionOptionSplashes: SplashState = {
  ids: [],
  entities: {},
  error: '',
};

export const selectionSplashInitialState =
  selectionSplashAdapter.getInitialState(defaultSelectionOptionSplashes);

// THICKNESSES
export interface SelectionThicknessFormValue {
  _id: string;
  thickness: string;
}

export const THICKNESS_FORM_ID = 'thicknessForm';

export const initialThicknessFormState =
  createFormGroupState<SelectionThicknessFormValue>(THICKNESS_FORM_ID, {
    _id: null,
    thickness: '',
  });

interface ThicknessState extends EntityState<SelectionOptionThicknesses> {
  error: string;
}

export const selectionThicknessAdapter: EntityAdapter<SelectionOptionThicknesses> =
  createEntityAdapter<SelectionOptionThicknesses>({
    selectId: (thicknessSelectionOptions: SelectionOptionThicknesses) =>
      thicknessSelectionOptions._id,
  });

export const defaultSelectionOptionThicknesses: ThicknessState = {
  ids: [],
  entities: {},
  error: '',
};

export const selectionThicknessInitialState =
  selectionThicknessAdapter.getInitialState(defaultSelectionOptionThicknesses);

// INSTALLER CREWS
export interface SelectionInstallerCrewFormValue {
  _id: string;
  crew: string;
}

export const INSTALLER_CREW_FORM_ID = 'installerCrewForm';

export const initialInstallerCrewFormState =
  createFormGroupState<SelectionInstallerCrewFormValue>(
    INSTALLER_CREW_FORM_ID,
    {
      _id: null,
      crew: '',
    }
  );

interface InstallerCrewState
  extends EntityState<SelectionOptionInstallerCrews> {
  error: string;
}

export const selectionInstallerCrewAdapter: EntityAdapter<SelectionOptionInstallerCrews> =
  createEntityAdapter<SelectionOptionInstallerCrews>({
    selectId: (installerCrewSelectionOptions: SelectionOptionInstallerCrews) =>
      installerCrewSelectionOptions._id,
  });

export const defaultSelectionOptionInstallerCrews: InstallerCrewState = {
  ids: [],
  entities: {},
  error: '',
};

export const selectionInstallerCrewInitialState =
  selectionInstallerCrewAdapter.getInitialState(
    defaultSelectionOptionInstallerCrews
  );

export interface State {
  selectionOptions: string[];
  colors: ColorState;
  colorForm: FormGroupState<SelectionColorFormValue>;
  products: ProductsState;
  productForm: FormGroupState<SelectionProductFormValue>;
  profiles: ProfileState;
  profileForm: FormGroupState<SelectionProfileFormValue>;
  splashes: SplashState;
  splashForm: FormGroupState<SelectionSplashFormValue>;
  thicknesses: ThicknessState;
  thicknessForm: FormGroupState<SelectionThicknessFormValue>;
  installerCrews: InstallerCrewState;
  installerCrewForm: FormGroupState<SelectionInstallerCrewFormValue>;
  error: string;
}

export const initialState: State = {
  selectionOptions: [],
  colors: defaultSelectionOptionColors,
  colorForm: initialColorFormState,
  products: defaultSelectionOptionProducts,
  productForm: initialProductFormState,
  profiles: defaultSelectionOptionProfiles,
  profileForm: initialProfileFormState,
  splashes: defaultSelectionOptionSplashes,
  splashForm: initialSplashFormState,
  thicknesses: defaultSelectionOptionThicknesses,
  thicknessForm: initialThicknessFormState,
  installerCrews: defaultSelectionOptionInstallerCrews,
  installerCrewForm: initialInstallerCrewFormState,
  error: '',
};
