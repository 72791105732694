import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@surface-elements/shared/environments';
import { Observable } from 'rxjs';
import { User } from '@surface-elements/shared/shared-ui';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userUrl = `${environment.apiUrl}/users`;
  private authUrl = `${this.userUrl}/auth`;
  constructor(private http: HttpClient) {}

  logIn(emailAddress: string, password: string): Observable<any> {
    return this.http.post<User>(this.authUrl, { emailAddress, password });
  }

  resetPassword(userName: string): Observable<any> {
    return this.http.post<any>(`${this.userUrl}/resetpassword`, { userName });
  }

  verifyResetPasswordToken(token: string): Observable<{ userName; userId }> {
    return this.http.get<any>(`${this.userUrl}/resetpassword/${token}`);
  }

  updatePassword(
    userId: string,
    token: string,
    password: string
  ): Observable<any> {
    return this.http.post<any>(`${this.userUrl}/${userId}/updatepassword`, {
      token,
      password,
    });
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }
}
