import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@surface-elements/shared/environments';
import {
  SelectionOptionColors,
  SelectionOptionInstallerCrews,
  SelectionOptionProducts,
  SelectionOptionProfiles,
  SelectionOptionSplashes,
  SelectionOptionThicknesses,
  SelectionOptionsEnum
} from '@surface-elements/shared/shared-ui';

@Injectable({
  providedIn: 'root',
})
export class SelectionDataService {
  private selectionsUrl = `${environment.apiUrl}/settings`;

  constructor(private http: HttpClient) {}

  getAllSelections(): Observable<{ selections: SelectionOptionsEnum[] }> {
    return this.http.get<{ selections: SelectionOptionsEnum[] }>(
      this.selectionsUrl + '/selections'
    );
  }

  getColorSelections(): Observable<SelectionOptionColors[]> {
    return this.http.get<SelectionOptionColors[]>(
      `${this.selectionsUrl}/selections/colors`
    );
  }

  createColorSelection(
    payload: SelectionOptionColors
  ): Observable<SelectionOptionColors> {
    return this.http
      .post<SelectionOptionColors>(
        `${this.selectionsUrl}/selections/colors`,
        payload
      )
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateColorSelection(
    payload: SelectionOptionColors,
    id: string
  ): Observable<SelectionOptionColors> {
    return this.http.patch<SelectionOptionColors>(
      `${this.selectionsUrl}/selections/colors/${id}`,
      payload
    );
  }

  getProductSelections(): Observable<SelectionOptionProducts[]> {
    return this.http.get<SelectionOptionProducts[]>(
      `${this.selectionsUrl}/selections/products`
    );
  }

  createProductSelection(
    payload: SelectionOptionProducts
  ): Observable<SelectionOptionProducts> {
    return this.http
      .post<SelectionOptionProducts>(
        `${this.selectionsUrl}/selections/products`,
        payload
      )
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateProductSelection(
    payload: SelectionOptionProducts,
    id: string
  ): Observable<SelectionOptionProducts> {
    return this.http.patch<SelectionOptionProducts>(
      `${this.selectionsUrl}/selections/products/${id}`,
      payload
    );
  }

  getProfileSelections(): Observable<SelectionOptionProfiles[]> {
    return this.http.get<SelectionOptionProfiles[]>(
      `${this.selectionsUrl}/selections/profiles`
    );
  }

  createProfileSelection(
    payload: SelectionOptionProfiles
  ): Observable<SelectionOptionProfiles> {
    return this.http
      .post<SelectionOptionProfiles>(
        `${this.selectionsUrl}/selections/profiles`,
        payload
      )
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateProfileSelection(
    payload: SelectionOptionProfiles,
    id: string
  ): Observable<SelectionOptionProfiles> {
    return this.http.patch<SelectionOptionProfiles>(
      `${this.selectionsUrl}/selections/profiles/${id}`,
      payload
    );
  }

  getSplashSelections(): Observable<SelectionOptionSplashes[]> {
    return this.http.get<SelectionOptionSplashes[]>(
      `${this.selectionsUrl}/selections/splashes`
    );
  }

  createSplashSelection(
    payload: SelectionOptionSplashes
  ): Observable<SelectionOptionSplashes> {
    return this.http
      .post<SelectionOptionSplashes>(
        `${this.selectionsUrl}/selections/splashes`,
        payload
      )
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateSplashSelection(
    payload: SelectionOptionSplashes,
    id: string
  ): Observable<SelectionOptionSplashes> {
    return this.http.patch<SelectionOptionSplashes>(
      `${this.selectionsUrl}/selections/splashes/${id}`,
      payload
    );
  }

  getThicknessSelections(): Observable<SelectionOptionThicknesses[]> {
    return this.http.get<SelectionOptionThicknesses[]>(
      `${this.selectionsUrl}/selections/thicknesses`
    );
  }

  createThicknessSelection(
    payload: SelectionOptionThicknesses
  ): Observable<SelectionOptionThicknesses> {
    return this.http
      .post<SelectionOptionThicknesses>(
        `${this.selectionsUrl}/selections/thicknesses`,
        payload
      )
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateThicknessSelection(
    payload: SelectionOptionThicknesses,
    id: string
  ): Observable<SelectionOptionThicknesses> {
    return this.http.patch<SelectionOptionThicknesses>(
      `${this.selectionsUrl}/selections/thicknesses/${id}`,
      payload
    );
  }

  getInstallerCrewSelections(): Observable<SelectionOptionInstallerCrews[]> {
    return this.http.get<SelectionOptionInstallerCrews[]>(
      `${this.selectionsUrl}/selections/installer_crews`
    );
  }

  createInstallerCrewSelection(
    payload: SelectionOptionInstallerCrews
  ): Observable<SelectionOptionInstallerCrews> {
    return this.http
      .post<SelectionOptionInstallerCrews>(
        `${this.selectionsUrl}/selections/installer_crews`,
        payload
      )
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateInstallerCrewSelection(
    payload: SelectionOptionInstallerCrews,
    id: string
  ): Observable<SelectionOptionInstallerCrews> {
    return this.http.patch<SelectionOptionInstallerCrews>(
      `${this.selectionsUrl}/selections/installer_crews/${id}`,
      payload
    );
  }
  deleteSelectionsEntity(
    id: string,
    entity: string
  ): Observable<SelectionOptionColors> {
    return this.http
      .delete<SelectionOptionColors>(`${this.selectionsUrl}/selections/${entity}/${id}`)
      .pipe(catchError((error: string) => throwError(error)));
  }
}
