import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@surface-elements/shared/environments';
import { Account } from '@surface-elements/accounts/domain';
import { Job } from '@surface-elements/jobs/domain';
import { Contact } from '@surface-elements/contacts/domain';

@Injectable({
  providedIn: 'root',
})
export class ContactDataService {
  private contactsUrl = `${environment.apiUrl}/contacts`;

  constructor(private http: HttpClient) {}

  getContacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.contactsUrl);
  }

  createContact(payload: Contact): Observable<Contact> {
    return this.http
      .post<Contact>(this.contactsUrl, payload)
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateContact(payload: Contact, id: string): Observable<Contact> {
    return this.http.patch<Contact>(`${this.contactsUrl}/${id}`, payload);
  }

  deleteContact(payload: Contact): Observable<Contact> {
    return this.http
      .delete<Contact>(`${this.contactsUrl}/${payload._id}`)
      .pipe(catchError((error: string) => throwError(error)));
  }

  linkJobContact(
    contact: Contact,
    job: Job
  ): Observable<{ contact: Contact; job: Job }> {
    const linkedContact: Contact = {
      ...contact,
    };

    delete linkedContact.createdAt;
    delete linkedContact.updatedAt;
    delete linkedContact._id;

    const linkedJob: Job = {
      ...job,
    };

    delete linkedJob.createdAt;
    delete linkedJob.updatedAt;
    delete linkedJob._id;
    delete linkedJob.jobNumber;

    return forkJoin([
      this.http.patch<Contact>(
        `${this.contactsUrl}/${contact._id}`,
        linkedContact
      ),
      this.http.patch<Job>(`${environment.apiUrl}/jobs/${job._id}`, linkedJob),
    ]).pipe(
      map((data) => {
        return { contact: data[0], job: data[1] };
      })
    );
  }

  unlinkJobContact(
    contact: Contact,
    job: Job
  ): Observable<{ contact: Contact; job: Job }> {
    const jobsArray = (contact.jobs as string[]).filter((jobId) => {
      return jobId !== job._id;
    });
    const contactsArray = (job.contacts as Contact[])
      .filter((jobContact) => {
        return jobContact._id !== contact._id;
      })
      .map((contact) => contact._id);

    const unlinkedContact: Contact = {
      ...contact,
      jobs: jobsArray,
    };

    delete unlinkedContact.createdAt;
    delete unlinkedContact.updatedAt;
    delete unlinkedContact._id;

    const unlinkedJob: Job = {
      ...job,
      contacts: contactsArray,
    };

    delete unlinkedJob.createdAt;
    delete unlinkedJob.updatedAt;
    delete unlinkedJob._id;
    delete unlinkedJob.jobNumber;

    return forkJoin([
      this.http.patch<Contact>(
        `${this.contactsUrl}/${contact._id}`,
        unlinkedContact
      ),
      this.http.patch<Job>(
        `${environment.apiUrl}/jobs/${job._id}`,
        unlinkedJob
      ),
    ]).pipe(
      map((data) => {
        return { contact: data[0], job: data[1] };
      })
    );
  }

  linkAccountContact(
    contact: Contact,
    account: Account
  ): Observable<{ contact: Contact; account: Account }> {
    const linkedContact: Contact = {
      ...contact,
    };

    delete linkedContact.createdAt;
    delete linkedContact.updatedAt;
    delete linkedContact._id;

    const linkedAccount: Account = {
      ...account,
    };

    delete linkedAccount.createdAt;
    delete linkedAccount.updatedAt;
    delete linkedAccount._id;

    return forkJoin([
      this.http.patch<Contact>(
        `${this.contactsUrl}/${contact._id}`,
        linkedContact
      ),
      this.http.patch<Account>(
        `${environment.apiUrl}/accounts/${account._id}`,
        linkedAccount
      ),
    ]).pipe(
      map((data) => {
        return { contact: data[0], account: data[1] };
      })
    );
  }

  unlinkAccountContact(
    contact: Contact,
    account: Account
  ): Observable<{ contact: Contact; account: Account }> {
    const accountsArray = (contact.accounts as string[]).filter((accountId) => {
      return accountId !== account._id;
    });
    const contactsArray = (account.contacts as Contact[])
      .filter((accountContact) => {
        return accountContact._id !== contact._id;
      })
      .map((contact) => contact._id);

    const unlinkedContact: Contact = {
      ...contact,
      accounts: accountsArray,
    };

    delete unlinkedContact.createdAt;
    delete unlinkedContact.updatedAt;
    delete unlinkedContact._id;

    const unlinkedAccount: Account = {
      ...account,
      contacts: contactsArray,
    };

    delete unlinkedAccount.createdAt;
    delete unlinkedAccount.updatedAt;
    delete unlinkedAccount._id;

    return forkJoin([
      this.http.patch<Contact>(
        `${this.contactsUrl}/${contact._id}`,
        unlinkedContact
      ),
      this.http.patch<Account>(
        `${environment.apiUrl}/accounts/${account._id}`,
        unlinkedAccount
      ),
    ]).pipe(
      map((data) => {
        return { contact: data[0], account: data[1] };
      })
    );
  }
}
