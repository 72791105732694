import { ValidationErrors } from "@angular/forms";

export const customDuplicateNameValidator = (
  inputString: string | null | undefined,
  accountNames: string[]
): ValidationErrors => {
  let errors: ValidationErrors = null;
  if (accountNames.filter((name) => name.toLowerCase() === inputString.toLowerCase()).length > 0) {
    errors = {};
    errors['duplicateName'] = 'Account name already exists';
  }
  return errors;
}
