import { NgModule, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { omsFeatureShellRoutes } from './lib.routes';
import { ShellComponent } from './shell/shell.component';

import { RootStoreModule, AppEffects, SharedDataAccessModule } from '@surface-elements/shared/data-access';
import { MenuModule } from '@surface-elements/shared/ui-navigation';

@NgModule({
  declarations: [ShellComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(omsFeatureShellRoutes, { useHash: true }),
    RootStoreModule,
    MenuModule,
    SharedDataAccessModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      name: 'OMS DevTools',
      maxAge: 25,
      logOnly: !isDevMode(),
      connectInZone: true}),
  ],
  exports: [ShellComponent]
})
export class OmsFeatureShellModule {}
