import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { JobEffects } from './job.effects';
import { jobReducer } from './job.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('jobs', jobReducer),
    EffectsModule.forFeature([JobEffects]),
  ],
})
export class JobStoreModule {}
