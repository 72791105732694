import { createReducer, on } from '@ngrx/store';

import {
  initialState,
  accountAdapter,
  State,
  AccountFormValue,
  ACCOUNT_FORM_ID,
  INITIAL_ACCOUNT_FORM_STATE,
} from './account.state';

import * as accountActions from './account.actions';
import {
  createFormGroupState,
  onNgrxForms,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { email, maxLength, minLength, pattern, required } from 'ngrx-forms/validation';
import { Address, Email, Phone } from '@surface-elements/shared/shared-ui';

const reducer = createReducer<State>(
  initialState,
  onNgrxForms(),
  on(accountActions.resetMyForm, (state): State => {
    return {
      ...state,
      accountForm: createFormGroupState<AccountFormValue>(
        ACCOUNT_FORM_ID,
        INITIAL_ACCOUNT_FORM_STATE.value
      )
    }
  }),
  on(accountActions.SetEditingValueAccountAction, (state, action): State => {
    return {
      ...state,
      accountForm: createFormGroupState<AccountFormValue>(
        ACCOUNT_FORM_ID,
        action.editValue
      ),
    };
  }),
  on(accountActions.loadAccountsSuccess, (state, action): State => {
    return accountAdapter.setAll(action.accounts, {
      ...state,
      error: '',
    });
  }),
  on(accountActions.loadAccountsFailure, (state, action): State => {
    return {
      ...state,
      entities: {},
      error: action.error,
    };
  }),
  on(accountActions.createAccountSuccess, (state, action): State => {
    state = {
      ...state,
      error: '',
      selectedAccountId: action._id,
    };
    return accountAdapter.addOne(action, state);
  }),
  on(accountActions.createAccountFail, (state, action): State => {
    return {
      ...state,
      selectedAccountId: null,
      error: action.error,
    };
  }),
  on(accountActions.deleteAccountSuccess, (state, { id }): State => {
    return accountAdapter.removeOne(id, state);
  }),
  on(accountActions.deleteAccountFail, (state, action): State => {
    return {
      ...state,
      selectedAccountId: null,
      error: action.error,
    };
  }),
  on(accountActions.updateAccountSuccess, (state, action): State => {
    return accountAdapter.updateOne(action, state);
  }),
  on(accountActions.updateAccountFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(accountActions.setCurrentAccount, (state, action): State => {
    return {
      ...state,
      error: '',
      selectedAccountId: action.currentAccountNumber,
    };
  }),
  on(accountActions.selectAccountIds, (state, action): State => {
    return {
      ...state,
      selectedIds: action.selectedIds,
    };
  }),
  on(accountActions.clearCurrentAccount, (state): State => {
    return {
      ...state,
      selectedAccountId: null,
    };
  }),
  on(accountActions.clearSelectedIds, (state): State => {
    return {
      ...state,
      selectedIds: [],
    };
  })
);

const validateAccountForm = updateGroup<AccountFormValue>({
  name: validate<string>(required, minLength(3)),
  address: updateGroup<Address>({
    state: validate<string>(maxLength(2))
  }),
  phone: updateGroup<Phone>({
    number: validate<string>(pattern(/(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/)),
  }),
  email: updateGroup<Email>({
    emailAddress: validate<string>(email)
  }),
  website: validate<string>(pattern(/^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/)),
});

export const accountReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.accountForm,
  validateAccountForm
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  accountAdapter.getSelectors();
