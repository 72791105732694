import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgrxFormsModule } from 'ngrx-forms';
import { FormButtonsComponent } from './form-buttons/form-buttons.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { BsScreensizeComponent } from './dev-tools/bs-screensize/bs-screensize.component';
import { MessageComponent } from './message/message.component';
import { SortableDirective } from './directives/sortable.directive';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgbTableComponent } from './ngb-table/ngb-table.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { RouteHeaderComponent } from './route-header/route-header.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BsDatepickerModule.forRoot(),
    NgrxFormsModule,
    NgbAccordionModule,
  ],
  declarations: [
    FormButtonsComponent,
    FormHeaderComponent,
    BsScreensizeComponent,
    MessageComponent,
    SortableDirective,
    DropdownComponent,
    NgbTableComponent,
    ReportListComponent,
    ConfirmDialogComponent,
    RouteHeaderComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FormButtonsComponent,
    FormHeaderComponent,
    BsDatepickerModule,
    BsScreensizeComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    MessageComponent,
    SortableDirective,
    NgrxFormsModule,
    DropdownComponent,
    NgbTableComponent,
    ReportListComponent,
    ConfirmDialogComponent,
    RouteHeaderComponent,
    NgbAccordionModule,
  ],
  providers: [provideEnvironmentNgxMask(maskConfig)],
})
export class SharedSharedUiModule {}
