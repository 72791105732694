import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AuthStoreModule } from './auth-store/auth-store.module';
import { AccountsDataAccessModule } from '@surface-elements/accounts/data-access';
import { JobsDataAccessModule } from '@surface-elements/jobs/data-access';
import { OrdersDataAccessModule } from '@surface-elements/orders/data-access';
import { SettingsDataAccessModule } from '@surface-elements/settings/data-access';
import { ReportsDataAccessModule } from '@surface-elements/reports/data-access';
import { ContactsDataAccessModule } from '@surface-elements/contacts/data-access';

@NgModule({
  declarations: [],
  imports: [
    AccountsDataAccessModule,
    JobsDataAccessModule,
    ContactsDataAccessModule,
    AuthStoreModule,
    OrdersDataAccessModule,
    SettingsDataAccessModule,
    ReportsDataAccessModule,
    StoreModule.forRoot({}),
  ]
})
export class RootStoreModule {}
