import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import {
  SelectionOptionColors,
  SelectionOptionProducts,
  SelectionOptionProfiles,
  SelectionOptionSplashes,
  SelectionOptionThicknesses,
  SelectionOptionInstallerCrews,
  SelectionOptionsEnum
} from '@surface-elements/shared/shared-ui';
import {
  SelectionColorFormValue,
  SelectionProductFormValue,
  SelectionProfileFormValue,
  SelectionSplashFormValue,
  SelectionThicknessFormValue,
  SelectionInstallerCrewFormValue,
} from './selection.state';

// SELECTION OPTIONS
export const loadSelectionOptions = createAction(
  '[SELECTION] Load Selection Options'
);

export const loadSelectionOptionsSuccess = createAction(
  '[SELECTION] Load Selection Options Success',
  props<{ selectionOptions: SelectionOptionsEnum[] }>()
);

export const loadSelectionOptionsFailure = createAction(
  '[SELECTION] Load Selection Options Fail',
  props<{ error: string }>()
);

// COLORS

// Load Colors
export const loadColorSelections = createAction(
  '[SELECTION COLORS] Load Color Selections'
);

export const loadColorSelectionsSuccess = createAction(
  '[SELECTION COLORS] Load Color Selections Success',
  props<{ selectionOptionColors: SelectionOptionColors[] }>()
);

export const loadColorSelectionsFailure = createAction(
  '[SELECTION COLORS] Load Color Selections Fail',
  props<{ error: string }>()
);

// create color
export const createColor = createAction(
  '[SELECTION COLORS] Create color',
  props<{ color: SelectionOptionColors }>()
);

export const createColorFail = createAction(
  '[SELECTION COLORS] Create color Fail',
  props<{ error: string }>()
);

export const createColorSuccess = createAction(
  '[SELECTION COLORS] Create color Success',
  props<SelectionOptionColors>()
);

// delete color
export const deleteColor = createAction(
  '[SELECTION COLORS] Delete color',
  props<{ id: string; entity: string }>()
);

export const deleteColorFail = createAction(
  '[SELECTION COLORS] Delete color Fail',
  props<{ error: string }>()
);

export const deleteColorSuccess = createAction(
  '[SELECTION COLORS] Delete color Success',
  props<{ id: string }>()
);

// update color
export const updateColor = createAction(
  '[SELECTION COLORS] Update Color',
  props<{ color: SelectionOptionColors; id: string }>()
);

export const updateColorFail = createAction(
  '[SELECTION COLORS] Update Color Fail',
  props<{ error: string }>()
);

export const updateColorSuccess = createAction(
  '[SELECTION COLORS] Update Color Success',
  props<Update<SelectionOptionColors>>()
);

// color form
export const SetEditingValueSelectionColorAction = createAction(
  '[SELECTION COLORS FORM] Set editing value',
  props<{ editValue: SelectionColorFormValue }>()
);

// PRODUCT

// load products
export const loadProductSelections = createAction(
  '[SELECTION PRODUCTS] Load Product Selections'
);

export const loadProductSelectionsSuccess = createAction(
  '[SELECTION PRODUCTS] Load Product Selections Success',
  props<{ selectionOptionProducts: SelectionOptionProducts[] }>()
);

export const loadProductSelectionsFailure = createAction(
  '[SELECTION PRODUCTS] Load Product Selections Fail',
  props<{ error: string }>()
);

// create product
export const createProduct = createAction(
  '[SELECTION PRODUCTS] Create product',
  props<{ product: SelectionOptionProducts }>()
);

export const createProductFail = createAction(
  '[SELECTION PRODUCTS] Create product Fail',
  props<{ error: string }>()
);

export const createProductSuccess = createAction(
  '[SELECTION PRODUCTS] Create product Success',
  props<SelectionOptionProducts>()
);

// delete product
export const deleteProduct = createAction(
  '[SELECTION PRODUCTS] Delete product',
  props<{ id: string; entity: string }>()
);

export const deleteProductFail = createAction(
  '[SELECTION PRODUCTS] Delete product Fail',
  props<{ error: string }>()
);

export const deleteProductSuccess = createAction(
  '[SELECTION PRODUCTS] Delete product Success',
  props<{ id: string }>()
);

// update product
export const updateProduct = createAction(
  '[SELECTION PRODUCTS] Update Product',
  props<{ product: SelectionOptionProducts; id: string }>()
);

export const updateProductFail = createAction(
  '[SELECTION PRODUCTS] Update Product Fail',
  props<{ error: string }>()
);

export const updateProductSuccess = createAction(
  '[SELECTION PRODUCTS] Update Product Success',
  props<Update<SelectionOptionProducts>>()
);

// product form
export const SetEditingValueSelectionProductAction = createAction(
  '[SELECTION PRODUCT FORM] Set editing value',
  props<{ editValue: SelectionProductFormValue }>()
);

// PROFILE

// load profiles
export const loadProfileSelections = createAction(
  '[SELECTION PROFILES] Load Profile Selections'
);

export const loadProfileSelectionsSuccess = createAction(
  '[SELECTION PROFILES] Load Profile Selections Success',
  props<{ selectionOptionProfiles: SelectionOptionProfiles[] }>()
);

export const loadProfileSelectionsFailure = createAction(
  '[SELECTION PROFILES] Load Profile Selections Fail',
  props<{ error: string }>()
);

// create profile
export const createProfile = createAction(
  '[SELECTION PROFILES] Create profile',
  props<{ profile: SelectionOptionProfiles }>()
);

export const createProfileFail = createAction(
  '[SELECTION PROFILES] Create profile Fail',
  props<{ error: string }>()
);

export const createProfileSuccess = createAction(
  '[SELECTION PROFILES] Create profile Success',
  props<SelectionOptionProfiles>()
);

// delete profile
export const deleteProfile = createAction(
  '[SELECTION PROFILES] Delete profile',
  props<{ id: string; entity: string }>()
);

export const deleteProfileFail = createAction(
  '[SELECTION PROFILES] Delete profile Fail',
  props<{ error: string }>()
);

export const deleteProfileSuccess = createAction(
  '[SELECTION PROFILES] Delete product Success',
  props<{ id: string }>()
);

// update profile
export const updateProfile = createAction(
  '[SELECTION PROFILES] Update Profile',
  props<{ profile: SelectionOptionProfiles; id: string }>()
);

export const updateProfileFail = createAction(
  '[SELECTION PROFILES] Update Profile Fail',
  props<{ error: string }>()
);

export const updateProfileSuccess = createAction(
  '[SELECTION PROFILES] Update Profile Success',
  props<Update<SelectionOptionProfiles>>()
);

// profile form
export const SetEditingValueSelectionProfileAction = createAction(
  '[SELECTION PROFILE FORM] Set editing value',
  props<{ editValue: SelectionProfileFormValue }>()
);

// SPLASH

// load splashes
export const loadSplashSelections = createAction(
  '[SELECTION SPLASH] Load Splash Selections'
);

export const loadSplashSelectionsSuccess = createAction(
  '[SELECTION SPLASH] Load Splash Selections Success',
  props<{ selectionOptionSplashes: SelectionOptionSplashes[] }>()
);

export const loadSplashSelectionsFailure = createAction(
  '[SELECTION SPLASH] Load Splash Selections Fail',
  props<{ error: string }>()
);

// create splash
export const createSplash = createAction(
  '[SELECTION SPLASH] Create splash',
  props<{ splash: SelectionOptionSplashes }>()
);

export const createSplashFail = createAction(
  '[SELECTION SPLASH] Create splash Fail',
  props<{ error: string }>()
);

export const createSplashSuccess = createAction(
  '[SELECTION SPLASH] Create splash Success',
  props<SelectionOptionSplashes>()
);

// delete splash
export const deleteSplash = createAction(
  '[SELECTION SPLASHES] Delete splash',
  props<{ id: string; entity: string }>()
);

export const deleteSplashFail = createAction(
  '[SELECTION SPLASHES] Delete splash Fail',
  props<{ error: string }>()
);

export const deleteSplashSuccess = createAction(
  '[SELECTION SPLASHES] Delete splash Success',
  props<{ id: string }>()
);

// update splash
export const updateSplash = createAction(
  '[SELECTION SPLASHES] Update Splash',
  props<{ splash: SelectionOptionSplashes; id: string }>()
);

export const updateSplashFail = createAction(
  '[SELECTION SPLASHES] Update Splash Fail',
  props<{ error: string }>()
);

export const updateSplashSuccess = createAction(
  '[SELECTION SPLASHES] Update Splash Success',
  props<Update<SelectionOptionSplashes>>()
);

// splash form
export const SetEditingValueSelectionSplashAction = createAction(
  '[SELECTION SPLASH FORM] Set editing value',
  props<{ editValue: SelectionSplashFormValue }>()
);

// THICKNESS

// load thicknesses
export const loadThicknessSelections = createAction(
  '[SELECTION THICKNESSES] Load Thickness Selections'
);

export const loadThicknessSelectionsSuccess = createAction(
  '[SELECTION THICKNESSES] Load Thickness Selections Success',
  props<{ selectionOptionThicknesses: SelectionOptionThicknesses[] }>()
);

export const loadThicknessSelectionsFailure = createAction(
  '[SELECTION THICKNESSES] Load Thickness Selections Fail',
  props<{ error: string }>()
);

// create thickness
export const createThickness = createAction(
  '[SELECTION THICKNESSES] Create thickness',
  props<{ thickness: SelectionOptionThicknesses }>()
);

export const createThicknessFail = createAction(
  '[SELECTION THICKNESSES] Create thickness Fail',
  props<{ error: string }>()
);

export const createThicknessSuccess = createAction(
  '[SELECTION THICKNESSES] Create thickness Success',
  props<SelectionOptionThicknesses>()
);

// delete thickness
export const deleteThickness = createAction(
  '[SELECTION THICKNESSES] Delete thickness',
  props<{ id: string; entity: string }>()
);

export const deleteThicknessFail = createAction(
  '[SELECTION THICKNESSES] Delete thickness Fail',
  props<{ error: string }>()
);

export const deleteThicknessSuccess = createAction(
  '[SELECTION THICKNESSES] Delete thickness Success',
  props<{ id: string }>()
);

// update thickness
export const updateThickness = createAction(
  '[SELECTION THICKNESSES] Update Thickness',
  props<{ thickness: SelectionOptionThicknesses; id: string }>()
);

export const updateThicknessFail = createAction(
  '[SELECTION THICKNESSES] Update Thickness Fail',
  props<{ error: string }>()
);

export const updateThicknessSuccess = createAction(
  '[SELECTION THICKNESSES] Update Thickness Success',
  props<Update<SelectionOptionThicknesses>>()
);

// thickness form
export const SetEditingValueSelectionThicknessAction = createAction(
  '[SELECTION THICKNESS FORM] Set editing value',
  props<{ editValue: SelectionThicknessFormValue }>()
);

// INSTALLER CREW

// load installer crews
export const loadInstallerCrewSelections = createAction(
  '[SELECTION INSTALLER CREWS] Load Installer Crews Selections'
);

export const loadInstallerCrewSelectionsSuccess = createAction(
  '[SELECTION INSTALLER CREWS] Load Installer Crews Success',
  props<{ selectionOptionInstallerCrews: SelectionOptionInstallerCrews[] }>()
);

export const loadInstallerCrewSelectionsFailure = createAction(
  '[SELECTION INSTALLER CREWS] Load Installer Crews Fail',
  props<{ error: string }>()
);

// create installer crew
export const createInstallerCrew = createAction(
  '[SELECTION INSTALLER CREWS] Create installer crew',
  props<{ crew: SelectionOptionInstallerCrews }>()
);

export const createInstallerCrewFail = createAction(
  '[SELECTION INSTALLER CREWS] Create installer crew Fail',
  props<{ error: string }>()
);

export const createInstallerCrewSuccess = createAction(
  '[SELECTION INSTALLER CREWS] Create installer crew Success',
  props<SelectionOptionInstallerCrews>()
);

// delete installer crew
export const deleteInstallerCrew = createAction(
  '[SELECTION INSTALLER CREWS] Delete installer crew',
  props<{ id: string; entity: string }>()
);

export const deleteInstallerCrewFail = createAction(
  '[SELECTION INSTALLER CREWS] Delete installer crew Fail',
  props<{ error: string }>()
);

export const deleteInstallerCrewSuccess = createAction(
  '[SELECTION INSTALLER CREWS] Delete installer crew Success',
  props<{ id: string }>()
);

// update installer crew
export const updateInstallerCrew = createAction(
  '[SELECTION INSTALLER CREWS] Update Install Crew',
  props<{ crew: SelectionOptionInstallerCrews; id: string }>()
);

export const updateInstallerCrewFail = createAction(
  '[SELECTION INSTALLER CREWS] Update Install Crew Fail',
  props<{ error: string }>()
);

export const updateInstallerCrewSuccess = createAction(
  '[SELECTION INSTALLER CREWS] Update Install Crew Success',
  props<Update<SelectionOptionInstallerCrews>>()
);

// installer crew form
export const SetEditingValueSelectionInstallerCrewAction = createAction(
  '[SELECTION INSTALLER CREWS FORM] Set editing value',
  props<{ editValue: SelectionInstallerCrewFormValue }>()
);
