<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="search-box">
  <form [formGroup]="f">
    <input
      formControlName="searchInput"
      class="form-control"
      type="search"
      placeholder="Search..."
      aria-label="Search"
      autocomplete="off"
      (blur)="onBlur()"
      (keyup)="onKeyUp()"
      (search)="onSearch()"
    />
  </form>
  <div class="search-result px-5" *ngIf="windowOpen$ | async">
    <ul class="list-group">
      <li
        class="list-group-item account-result"
        *ngFor="let account of accountResults"
        (click)="onAccountClick(account.id)"
      >
        <div class="row no-gutters">
          <div
            class="col-1 col-icon align-self-center d-flex justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-building"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
              />
              <path
                d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
              />
            </svg>
          </div>
          <div class="col-11">
            <span>{{ account.name }}</span>
          </div>
        </div>
      </li>
      <li
        class="list-group-item job-result"
        *ngFor="let job of jobResults"
        (click)="onJobClick(job.id)"
      >
        <div class="row no-gutters">
          <div
            class="col-1 col-icon align-self-center d-flex justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-house-door"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"
              />
            </svg>
          </div>
          <div class="col-11">
            <div class="row no-gutters">
              <div class="col-12">
                <span>{{ job.name }}</span
                ><br />
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12">
                <span class="job-address">
                  <div class="mb-0">
                    {{ job.address.street }}
                    <span
                      *ngIf="
                        job.address.city || job.address.state || job.address.zip
                      "
                      >,
                    </span>
                    {{ job.address.city }}
                    <span *ngIf="job.address.state || job.address.zip">, </span>
                    {{ job.address.state }} {{ job.address.zip }}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li
        class="list-group-item order-result"
        *ngFor="let order of orderResults"
        (click)="onOrderClick(order.id)"
      >
        <div class="row no-gutters">
          <div
            class="col-1 col-icon align-self-center d-flex justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-card-list"
              viewBox="0 0 16 16"
            >
              <path
                d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
              />
              <path
                d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"
              />
            </svg>
          </div>
          <div class="col-11">
            <span
              >{{ order.orderNumber }} -
              <span style="font-weight: bold"
                ><em>{{ order.jobName }}</em></span
              >
              - {{ order.customerPO }} - {{ order.vendorPO }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="list-group-item contact-result"
        *ngFor="let contact of contactResults"
        (click)="onContactClick(contact.id)"
      >
        <div class="row no-gutters">
          <div
            class="col-1 col-icon align-self-center d-flex justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-person-lines-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"
              />
            </svg>
          </div>
          <div class="col-11">
            <span class="contact-name"
              >{{ contact.firstName }} {{ contact.lastName }} </span
            ><span
              class="contact-role"
              *ngIf="contact['role'] !== '' && contact['role'] !== null"
              >- {{ contact['role'] }}</span
            >
            -
            <span class="contact-type"
              ><em>{{ contact.contactType }} contact</em></span
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
