import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AccountStoreActions, AccountStoreSelectors } from '@surface-elements/accounts/data-access';
import { JobStoreActions, JobStoreSelectors } from '@surface-elements/jobs/data-access';
import { OrderStoreActions, OrderStoreSelectors } from '@surface-elements/orders/data-access';
import { ContactStoreActions, ContactStoreSelectors } from '@surface-elements/contacts/data-access';

import { SearchResult } from './searchResult';
import { Job } from '@surface-elements/jobs/domain';
import { Account } from '@surface-elements/accounts/domain';
import { Order } from '@surface-elements/orders/domain';
import { Contact } from '@surface-elements/contacts/domain';
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private store: Store) {
    this.store.dispatch(AccountStoreActions.loadAccounts());
    this.store.dispatch(JobStoreActions.loadJobs());
    this.store.dispatch(OrderStoreActions.loadOrders());
    this.store.dispatch(ContactStoreActions.loadContacts());
  }

  search(term: string): Observable<SearchResult> {
    let accountResults = [];
    let jobResults = [];
    let orderResults = [];
    let contactResults = [];

    let allJobs: Job[] = [];

    const searchTerm = term.toLowerCase();

    // Search Accounts
    this.store
      .select(AccountStoreSelectors.getAccounts)
      .pipe(
        map((accounts: Account[]) => {
          return accounts
            .filter((account) =>
              account.name.toLowerCase().includes(searchTerm)
            )
            .map((account) => {
              return {
                id: account._id,
                name: account.name,
              };
            });
        })
      )
      .subscribe((results) => (accountResults = results));

    // Search Jobs
    this.store
      .select(JobStoreSelectors.getJobs)
      .pipe(
        tap((jobs: Job[]) => (allJobs = jobs)),
        map((jobs) => {
          return jobs
            .filter(
              (job) =>
                job.jobName.toLowerCase().includes(searchTerm) ||
                job.address.street?.toLocaleLowerCase().includes(searchTerm) ||
                job.address.city?.toLocaleLowerCase().includes(searchTerm) ||
                job.address.state?.toLocaleLowerCase().includes(searchTerm) ||
                job.address.zip?.toLocaleLowerCase().includes(searchTerm)
            )
            .map((job) => {
              return {
                id: job.jobNumber,
                name: job.jobName,
                address: job.address,
              };
            });
        })
      )
      .subscribe((results) => (jobResults = results));

    // Search Orders
    this.store
      .select(OrderStoreSelectors.getOrders)
      .pipe(
        map((orders: Order[]) => {
          return orders
            .filter(
              (order) =>
                order.orderNumber.toString().includes(searchTerm) ||
                order.customerPO?.toLowerCase().includes(searchTerm) ||
                order.vendorPO?.toLowerCase().includes(searchTerm)
            )
            .map((order) => {
              const jobName = allJobs.find((job) => job._id === order.job);
              return {
                id: order._id,
                orderNumber: order.orderNumber,
                customerPO: order.customerPO,
                vendorPO: order.vendorPO,
                jobName: jobName.jobName,
              };
            });
        })
      )
      .subscribe((results) => (orderResults = results));

    // Search Contacts
    this.store
      .select(ContactStoreSelectors.getContacts)
      .pipe(
        map((contacts: Contact[]) => {
          return contacts
            .filter(
              (contact) =>
                contact.firstName?.toLowerCase().includes(searchTerm) ||
                contact.lastName?.toLowerCase().includes(searchTerm) ||
                contact.role?.toLowerCase().includes(searchTerm)
            )
            .map((contact) => {
              return {
                id: contact._id,
                firstName: contact.firstName,
                lastName: contact.lastName,
                role: contact.role,
                contactType: contact.contactType,
              };
            });
        })
      )
      .subscribe((results) => (contactResults = results));

    const searchResults: SearchResult = {
      accounts: accountResults ? accountResults : [],
      jobs: jobResults ? jobResults : [],
      orders: orderResults ? orderResults : [],
      contacts: contactResults ? contactResults : [],
    };
    return of(searchResults);
  }
}
