import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SelectionOptionColors,
  SelectionOptionInstallerCrews,
  SelectionOptionProducts,
  SelectionOptionProfiles,
  SelectionOptionSplashes,
  SelectionOptionThicknesses,
} from '@surface-elements/shared/shared-ui';

import { State } from './selection.state';

const getSelectionOptionsFeatureState =
  createFeatureSelector<State>('selectionOptions');

export const getSelectionOptions = createSelector(
  getSelectionOptionsFeatureState,
  (state: State) => state.selectionOptions
);

export const getColors = createSelector(
  getSelectionOptionsFeatureState,
  (state: State) => Object.values(state.colors.entities)
);

export const getColorFromId = (colorId: string) =>
  createSelector(getColors, (colors: SelectionOptionColors[]) => {
    return colors.find((color) => color._id === colorId);
  });

export const getProducts = createSelector(
  getSelectionOptionsFeatureState,
  (state: State) => Object.values(state.products.entities)
);

export const getProductFromId = (productId: string) =>
  createSelector(getProducts, (products: SelectionOptionProducts[]) => {
    return products.find((product) => product._id === productId);
  });

export const getProfiles = createSelector(
  getSelectionOptionsFeatureState,
  (state: State) => Object.values(state.profiles.entities)
);

export const getProfileFromId = (profileId: string) =>
  createSelector(getProfiles, (profiles: SelectionOptionProfiles[]) => {
    return profiles.find((profile) => profile._id === profileId);
  });

export const getSplashes = createSelector(
  getSelectionOptionsFeatureState,
  (state: State) => Object.values(state.splashes.entities)
);

export const getSplashFromId = (splashId: string) =>
  createSelector(getSplashes, (splashes: SelectionOptionSplashes[]) => {
    return splashes.find((splash) => splash._id === splashId);
  });

export const getThicknesses = createSelector(
  getSelectionOptionsFeatureState,
  (state: State) => Object.values(state.thicknesses.entities)
);

export const getThicknessFromId = (thicknessId: string) =>
  createSelector(
    getThicknesses,
    (thicknesses: SelectionOptionThicknesses[]) => {
      return thicknesses.find((thickness) => thickness._id === thicknessId);
    }
  );

export const getInstallerCrews = createSelector(
  getSelectionOptionsFeatureState,
  (state: State) => Object.values(state.installerCrews.entities)
);

export const getInstallerCrewFromId = (installerCrewId: string) =>
  createSelector(
    getInstallerCrews,
    (installerCrews: SelectionOptionInstallerCrews[]) => {
      return installerCrews.find((crew) => crew._id === installerCrewId);
    }
  );

export const getAllSelections = createSelector(
  getColors,
  getProducts,
  getProfiles,
  getSplashes,
  getThicknesses,
  getInstallerCrews,
  (colors, products, profiles, splashes, thicknesses, installerCrews) => {
    return {
      colors,
      products,
      profiles,
      splashes,
      thicknesses,
      installerCrews,
    };
  }
);
