import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SettingsReportsService } from '@surface-elements/settings/data-access';
import { ApiError } from '@surface-elements/shared/domain';
import * as reportActions from './report.actions';
import { ReportConfig } from '@surface-elements/reports/domain';

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private settingsReportService: SettingsReportsService
  ) {}

  loadReportsConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.loadReportsConfig),
      mergeMap(() =>
        this.settingsReportService.getAllReportsConfig().pipe(
          map((reportsConfig) =>
            reportActions.loadReportsConfigSuccess({
              reportConfigs: reportsConfig,
            })
          ),
          catchError((error) =>
            of(reportActions.loadReportsConfigFailure({ error }))
          )
        )
      )
    );
  });

  updateReportConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.updateReportsConfig),
      mergeMap(({ reportConfig, id }) =>
        this.settingsReportService.updateReportsConfig(reportConfig, id).pipe(
          map((updatedConfig: ReportConfig) =>
            reportActions.updateReportsConfigSuccess({
              id,
              changes: updatedConfig,
            })
          ),
          catchError((error) =>
            of(reportActions.updateReportsConfigFailure({ error }))
          )
        )
      )
    );
  });

  deleteReportsConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.deleteReportsConfig),
      mergeMap((payload) =>
        this.settingsReportService
          .deleteReportsConfig(payload.reportConfigsId)
          .pipe(
            map(() =>
              reportActions.deleteReportsConfigSuccess({
                id: payload.reportConfigsId,
              })
            ),
            catchError((error: ApiError) =>
              of(
                reportActions.deleteReportsConfigFail({
                  error: error.error.error.message,
                })
              )
            )
          )
      )
    );
  });

  loadReportUserDefinedFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.loadReportUserDefinedFields),
      mergeMap(() =>
        this.settingsReportService.getAllReportUserDefinedFields().pipe(
          map((reportUserDefinedFields) =>
            reportActions.loadReportUserDefinedFieldsSuccess({
              reportUserDefinedFields: reportUserDefinedFields,
            })
          ),
          catchError((error) =>
            of(reportActions.loadReportUserDefinedFieldsFailure({ error }))
          )
        )
      )
    );
  });

  deleteReportUserDefinedField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.deleteReportUserDefinedField),
      mergeMap((payload) =>
        this.settingsReportService
          .deleteReportUserDefinedField(payload.reportUserDefinedFieldId)
          .pipe(
            map(() =>
              reportActions.deleteReportUserDefinedFieldSuccess({
                id: payload.reportUserDefinedFieldId,
              })
            ),
            catchError((error: ApiError) =>
              of(
                reportActions.deleteReportUserDefinedFieldFail({
                  error: error.error.error.message,
                })
              )
            )
          )
      )
    );
  });
}
