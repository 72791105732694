import { ValidationErrors } from "@angular/forms";

export const customDuplicateNameValidator = (
  inputString: string | null | undefined,
  jobNames: string[]
): ValidationErrors => {
  let errors: ValidationErrors = null;
  if (jobNames.filter((jobName) => jobName.toLowerCase() === inputString.toLowerCase()).length > 0) {
    errors = {};
    errors['duplicateName'] = 'Job name already exists';
  }
  return errors;
}
