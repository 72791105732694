import { createReducer, on } from '@ngrx/store';

import { initialState, State } from './auth.state';

import * as authActions from './auth.actions';

export const authReducer = createReducer<State>(
  initialState,
  on(authActions.loginSuccess, (state, action): State => {
    return {
      ...state,
      isAuthenticated: true,
      user: {
        _id: action.user._id,
        authToken: action.user.authToken,
        emailAddress: action.user.emailAddress,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        role: action.user.role,
      },
      errorMessage: null,
    };
  }),
  on(authActions.loginFailure, (state): State => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      errorMessage: 'Incorrect email and/or password.',
    };
  }),
  on(authActions.logOut, (): State => {
    return initialState;
  }),
  on(authActions.resetPasswordSuccess, (state): State => {
    return {
      ...state,
      resetPassword: {
        usernameSubmitted: true,
        usernameUpdated: false,
      },
    };
  }),
  on(authActions.resetPasswordFailure, (state, action): State => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      errorMessage: action.error,
    };
  }),
  on(authActions.updatePasswordSuccess, (state): State => {
    return {
      ...state,
      resetPassword: {
        usernameSubmitted: false,
        usernameUpdated: true,
      },
    };
  }),
  on(authActions.updatePasswordFailure, (state, action): State => {
    return {
      ...state,
      errorMessage: action.error,
    };
  })
);
