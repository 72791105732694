<div id="dialogContainer" class="px-3 py-4">
  <div class="row text-center">
    <h3 class="mb-1 mt-0">Are you sure?</h3>
  </div>
  <hr />
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div id="dialog" class="card">
          <article class="card-body">
            <div class="row">
              <div class="col">
                <div class="text-center">
                  <h5 class="card-title mb-2 mx-auto">
                    You have unsaved changes.
                  </h5>
                </div>
                <hr />
                <p>Click Cancel to return.</p>
                <p>Click Proceed to continue without saving changes.</p>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-6">
                <button
                  type="button"
                  class="btn btn-danger float-end"
                  (click)="onCancel()"
                >
                  Cancel
                </button>
              </div>
              <div class="col-6">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="onProceed()"
                >
                  Proceed
                </button>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
