import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';

/* NgRx */
import {
  ClearAsyncErrorAction,
  SetAsyncErrorAction,
  SetValueAction,
  StartAsyncValidationAction,
} from 'ngrx-forms';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as jobActions from './job.actions';
import * as jobSelectors from './job.selectors';

import { Job } from '@surface-elements/jobs/domain';
import { ApiError } from '@surface-elements/shared/domain';
import { JobDataService } from '../job-data.service';
import { customDuplicateNameValidator } from './job.validators';

@Injectable()
export class JobEffects {
  constructor(
    private actions$: Actions,
    private jobDataService: JobDataService,
    private router: Router,
    private store: Store
  ) {}

  loadJobs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(jobActions.loadJobs),
      mergeMap(() =>
        this.jobDataService.getJobs().pipe(
          map((jobs) => jobActions.loadJobsSuccess({ jobs })),
          catchError((error) => of(jobActions.loadJobsFailure({ error })))
        )
      )
    );
  });

  createJob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(jobActions.createJob),
      mergeMap((payload) => {
        return this.jobDataService.createJob(payload.job).pipe(
          map((data) => {
            return jobActions.createJobSuccess(data);
          }),
          catchError((error: ApiError) => {
            return of(
              jobActions.createJobFail({ error: error.error.error.message })
            );
          })
        );
      })
    );
  });

  createJobSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(jobActions.createJobSuccess),
        tap((payload) => this.router.navigate(['/jobs', payload.jobNumber]))
      );
    },
    { dispatch: false }
  );

  updateJob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(jobActions.updateJob),
      mergeMap(({ job, id }) =>
        this.jobDataService.updateJob(job, id).pipe(
          map((updateJob: Job) =>
            jobActions.updateJobSuccess({
              id: updateJob.jobNumber,
              changes: job,
            })
          ),
          catchError((error) => of(jobActions.updateJobFail({ error })))
        )
      )
    );
  });

  jobFormValidation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetValueAction.TYPE),
      filter((formControlUpdate: SetValueAction<string>) => {
        return formControlUpdate.controlId === 'jobForm.jobName';
      }),
      withLatestFrom(this.store.select(jobSelectors.getJobNames)),
      mergeMap(([formControlUpdate, jobNames]) => {
        const error = 'duplicateName';
        return of(customDuplicateNameValidator(formControlUpdate.value, jobNames)).pipe(
          map((err) => {
            return !err
              ? new ClearAsyncErrorAction(formControlUpdate.controlId, error)
              : new SetAsyncErrorAction(formControlUpdate.controlId, error, 'Job name alread exists');
          }),
          startWith(
            new StartAsyncValidationAction(formControlUpdate.controlId, error)
          )
        )
      })
    );
  });
}
