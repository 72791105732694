import { createReducer, on } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { maxLength, minLength, required } from 'ngrx-forms/validation';
import { Address } from '@surface-elements/shared/shared-ui';
import {
  jobAdapter,
  initialState,
  State,
  JobFormValue,
  JOB_FORM_ID,
  INITIAL_JOB_FORM_STATE,
} from './job.state';
import * as jobActions from './job.actions';

export const reducer = createReducer<State>(
  initialState,
  onNgrxForms(),
  on(jobActions.resetMyForm, (state): State => {
    return {
      ...state,
      jobForm: createFormGroupState<JobFormValue>(
        JOB_FORM_ID,
        INITIAL_JOB_FORM_STATE.value
      )
    }
  }),
  on(jobActions.SetEditingValueJobAction, (state, action): State => {
    return {
      ...state,
      jobForm: createFormGroupState<JobFormValue>(
        JOB_FORM_ID,
        action.editValue
      ),
    };
  }),
  on(jobActions.loadJobsSuccess, (state, action): State => {
    return jobAdapter.setAll(action.jobs, {
      ...state,
      error: '',
    });
  }),
  on(jobActions.loadJobsFailure, (state, action): State => {
    return {
      ...state,
      entities: {},
      error: action.error,
    };
  }),
  on(jobActions.setCurrentJob, (state, action): State => {
    return {
      ...state,
      selectedJobNumber: action.currentJobNumber,
    };
  }),
  on(jobActions.setSelectedJobNumbers, (state, action): State => {
    return {
      ...state,
      selectedJobNumber: null,
      selectedJobNumbers: action.selectedJobNumbers
        ? action.selectedJobNumbers
        : [],
    };
  }),
  on(jobActions.clearCurrentJob, (state): State => {
    return {
      ...state,
      selectedJobNumber: null,
    };
  }),
  on(jobActions.initializeCurrentJob, (state): State => {
    return {
      ...state,
      selectedJobNumber: null,
    };
  }),
  on(jobActions.createJobSuccess, (state, action): State => {
    state = {
      ...state,
      error: '',
      selectedJobNumber: action.jobNumber,
    };
    return jobAdapter.addOne(action, state);
  }),
  on(jobActions.createJobFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
      selectedJobNumber: null,
    };
  }),
  on(jobActions.updateJobSuccess, (state, action): State => {
    return jobAdapter.updateOne(action, state);
  }),
  on(jobActions.updateJobFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  })
);

const validateJobForm = updateGroup<JobFormValue>({
  jobName: validate<string>(required, minLength(3)),
  account: validate<string>(required),
  address: updateGroup<Address>({
    state: validate<string>(maxLength(2))
  }),
});

export const jobReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.jobForm,
  validateJobForm
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  jobAdapter.getSelectors();
