import { User } from '@surface-elements/shared/shared-ui';

export interface State {
  isAuthenticated: boolean;
  user: User | null;
  resetPassword: {
    usernameSubmitted: boolean;
    usernameUpdated: boolean;
  };
  errorMessage: string | null;
}

export const initialState: State = {
  isAuthenticated: false,
  user: null,
  resetPassword: {
    usernameSubmitted: false,
    usernameUpdated: false,
  },
  errorMessage: null,
};
