import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './auth.state';

export const getAuthFeatureState =
  createFeatureSelector<State>('authentication');

export const selectAuthState = createSelector(
  getAuthFeatureState,
  (state) => state
);

export const resetPasswordSubmitted = createSelector(
  getAuthFeatureState,
  (state) => state.resetPassword.usernameSubmitted
);

export const passwordUpdated = createSelector(
  getAuthFeatureState,
  (state) => state.resetPassword.usernameUpdated
);
