import { NgModule } from '@angular/core';
import { WidgetSearchBarComponent } from './widget-search-bar/widget-search-bar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [WidgetSearchBarComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  exports: [ReactiveFormsModule, FormsModule, WidgetSearchBarComponent],
})
export class SearchModule {}
