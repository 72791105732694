export enum ActivityName {
  'Template',
  'Drawn By',
  'Approval Sent',
  'Approval Received',
  'Submit',
  'Submit Accepted',
  'Fabricate',
  'Install',
  'Slabsmith Requested',
  "Slabsmith Rec'd from Cambria",
  'Slabsmith Sent to Dealer',
  "Slabsmith Rec'd from Dealer",
  'Slabsmith Sent to Cambria',
}

export interface Activity {
  name: keyof typeof ActivityName | null;
  date: string | null;
  status: string | null;
  initials?: string;
}
