import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthInterceptor } from './auth/auth.interceptor';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';

@NgModule({
  imports: [HttpClientModule, NgxSpinnerModule],
  declarations: [],
  exports: [NgxSpinnerModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class SharedDataAccessModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: SharedDataAccessModule) {
    super(parentModule);
  }
}
