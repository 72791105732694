import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { Contact } from '@surface-elements/contacts/domain';
import { Address, Email, Phone } from '@surface-elements/shared/shared-ui';

export interface ContactFormValue {
  _id: string;
  firstName: string;
  lastName: string;
  role: string;
  contactType: string;
  phones: Phone[];
  addresses: Address[];
  emails: Email[];
  website: string;
  note: string;
}

export const FORM_ID = 'contactForm';

export const initialFormState = createFormGroupState<ContactFormValue>(
  FORM_ID,
  {
    _id: null,
    firstName: '',
    lastName: '',
    role: '',
    contactType: '',
    phones: [],
    addresses: [],
    emails: [],
    website: '',
    note: '',
  }
);

export interface State extends EntityState<Contact> {
  selectedContactId: string | null;
  selectedIds: string[] | null;
  error: string;
  contactForm: FormGroupState<ContactFormValue>;
}

export const contactAdapter: EntityAdapter<Contact> =
  createEntityAdapter<Contact>({
    sortComparer: sortByContactLastName,
    selectId: (contact: Contact) => contact._id,
  });

function sortByContactLastName(cln1: Contact, cln2: Contact): number {
  return cln1.lastName.toLowerCase() < cln2.lastName.toLowerCase()
    ? -1
    : cln1.lastName.toLowerCase() > cln2.lastName.toLowerCase()
    ? 1
    : 0;
}

export const defaultContact: State = {
  ids: [],
  entities: {},
  selectedContactId: null,
  selectedIds: [],
  error: '',
  contactForm: initialFormState,
};

export const initialState = contactAdapter.getInitialState(defaultContact);
