import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import { RootStoreState, AuthStoreActions } from '@surface-elements/shared/data-access';

@Component({
  selector: 'oms-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css']
})
export class ShellComponent implements OnInit {
  constructor(
    private store: Store<RootStoreState.State>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e) => {
          return (e as NavigationEnd).url;
        }),
        take(1)
      )
      .subscribe((e) => {
        const urlSegments = e.split('/');
        if (urlSegments.length < 2 || urlSegments[2] !== 'resetpassword') {
          this.store.dispatch(AuthStoreActions.autoLogin());
        }
      });
  }
}
