import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { Job } from '@surface-elements/jobs/domain';
import { Address } from '@surface-elements/shared/shared-ui';

export interface JobFormValue {
  _id: string;
  account: string;
  jobName: string;
  address: Address;
  note: string;
}

export const JOB_FORM_ID = 'jobForm';

export const INITIAL_JOB_FORM_STATE =
  createFormGroupState<JobFormValue>(JOB_FORM_ID, {
    _id: null,
    account: '',
    jobName: '',
    address: {
      addressType: 'Other',
      street: '',
      city: '',
      state: '',
      zip: ''
    },
    note: ''
  })
export interface State extends EntityState<Job> {
  selectedJobNumber: number | null;
  selectedJobNumbers: number[] | null;
  jobForm: FormGroupState<JobFormValue>;
  error: string;
}

export const jobAdapter: EntityAdapter<Job> = createEntityAdapter<Job>({
  selectId: (job: Job) => job.jobNumber,
});

export const defaultJob: State = {
  ids: [],
  entities: {},
  selectedJobNumber: null,
  selectedJobNumbers: [],
  jobForm: INITIAL_JOB_FORM_STATE,
  error: '',
};

export const initialState = jobAdapter.getInitialState(defaultJob);
