import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { selectionReducer } from './selection.reducer';
import { SelectionEffects } from './selection.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('selectionOptions', selectionReducer),
    EffectsModule.forFeature([SelectionEffects]),
  ],
})
export class SelectionStoreModule {}
