import { createAction, props } from '@ngrx/store';
import { User } from '@surface-elements/shared/shared-ui';

export const login = createAction('[Auth] Login', props<User>());

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ user: User; redirect: boolean }>()
);

export const loginFailure = createAction('[Auth] Login Failure', props<any>());

export const autoLogin = createAction('[Auth] AutoLogin');

export const autoLogout = createAction(
  '[Auth] AutoLogout',
  props<{ expireMilliseconds: number }>()
);

export const logOut = createAction('[Auth] LogOut');

export const resetPassword = createAction(
  '[Auth] Send Reset Password Email',
  props<{ userName: string }>()
);

export const resetPasswordSuccess = createAction(
  '[Auth] Send Reset Password Email Success'
);

export const resetPasswordFailure = createAction(
  '[Auth] Send Reset Password Email Failure',
  props<{ error: string }>()
);

export const updatePassword = createAction(
  '[Auth] Update Password',
  props<{ userId: string; token: string; password: string }>()
);

export const updatePasswordSuccess = createAction(
  '[Auth] Update Password Success'
);

export const updatePasswordFailure = createAction(
  '[Auth] Update Password Failure',
  props<{ error: string }>()
);
