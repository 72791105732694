import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Contact } from '@surface-elements/contacts/domain';
import { ContactFormValue } from './contact.state';
import { Job } from '@surface-elements/jobs/domain';
import { Account } from '@surface-elements/accounts/domain';

// load contacts
export const loadContacts = createAction('[CONTACT] Load Contacts');

export const loadContactsSuccess = createAction(
  '[CONTACT] Load Contacts Success',
  props<{ contacts: Contact[] }>()
);

export const loadContactsFailure = createAction(
  '[CONTACT] Load Contacts Fail',
  props<{ error: string }>()
);

// create contact
export const createContact = createAction(
  '[CONTACT] Create Contact',
  props<{ contact: Contact }>()
);

export const createContactFail = createAction(
  '[CONTACT] Create Contact Fail',
  props<{ error: string }>()
);

export const createContactSuccess = createAction(
  '[CONTACT] Create Contact Success',
  props<Contact>()
);

// create account contact
export const createAccountContact = createAction(
  '[CONTACT] Create Account Contact',
  props<{ contact: Contact; accountId: string }>()
);

export const createAccountContactFail = createAction(
  '[CONTACT] Create Account Contact Fail',
  props<{ error: string }>()
);

export const createAccountContactSuccess = createAction(
  '[CONTACT] Create Account Contact Success',
  props<Contact>()
);

// create job contact
export const createJobContact = createAction(
  '[CONTACT] Create Job Contact',
  props<{ contact: Contact; jobId: string }>()
);

export const createJobContactFail = createAction(
  '[CONTACT] Create Account Contact Fail',
  props<{ error: string }>()
);

export const createJobContactSuccess = createAction(
  '[CONTACT] Create Account Contact Success',
  props<Contact>()
);

// update contact
export const updateContact = createAction(
  '[CONTACT] Update Contact',
  props<{ contact: Contact; id: string }>()
);

export const updateContactSuccess = createAction(
  '[CONTACT] Update Contact Success',
  props<Update<Contact>>()
);

export const updateContactFailure = createAction(
  '[CONTACT] Update Contact Fail',
  props<{ error: string }>()
);

// delete contact
export const deleteContact = createAction(
  '[CONTACT] Delete contact',
  props<{ contact: Contact }>()
);

export const deleteContactFail = createAction(
  '[CONTACT] Delete contact Fail',
  props<{ error: string }>()
);

export const deleteContactSuccess = createAction(
  '[CONTACT] Delete contact Success',
  props<{ id: string }>()
);

// link contact
export const linkJobContact = createAction(
  '[CONTACT] Link contact to job',
  props<{ contact: Contact; job: Job }>()
);

export const linkJobContactSuccess = createAction(
  '[CONTACT] Link contact to job Success',
  props<Update<Contact>>()
);

export const linkJobContactFail = createAction(
  '[CONTACT] Link contact to job Fail',
  props<{ error: string }>()
);

export const linkAccountContact = createAction(
  '[CONTACT] Link contact to account',
  props<{ contact: Contact; account: Account }>()
);

export const linkAccountContactSuccess = createAction(
  '[CONTACT] Link contact to account Success',
  props<Update<Contact>>()
);

export const linkAccountContactFail = createAction(
  '[CONTACT] Link contact to account Fail',
  props<{ error: string }>()
);

// unlink contact
export const unlinkJobContact = createAction(
  '[CONTACT] Unlink contact from job',
  props<{ contact: Contact; job: Job }>()
);

export const unlinkJobContactSuccess = createAction(
  '[CONTACT] Unlink contact from job Success',
  props<Update<Contact>>()
);

export const unlinkJobContactFail = createAction(
  '[CONTACT] Unlink contact from job Fail',
  props<{ error: string }>()
);

export const unlinkAccountContact = createAction(
  '[CONTACT] Unlink contact from account',
  props<{ contact: Contact; account: Account }>()
);

export const unlinkAccountContactSuccess = createAction(
  '[CONTACT] Unlink contact from account Success',
  props<Update<Contact>>()
);

export const unlinkAccountContactFail = createAction(
  '[CONTACT] Unlink contact from account Fail',
  props<{ error: string }>()
);

// current contact
export const setCurrentContact = createAction(
  '[CONTACT] Set Current Contact',
  props<{ currentContactId: string | null }>()
);

export const clearCurrentContact = createAction(
  '[CONTACT] Clear Current Contact'
);

export const clearSelectedIds = createAction('[CONTACT] Clear Selected Ids');

// contact form
export const SetEditingValueContactAction = createAction(
  '[CONTACT FORM] Set editing value',
  props<{ editValue: ContactFormValue }>()
);

export const resetMyForm = createAction('[CONTACT FORM] Reset form');
