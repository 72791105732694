import { createFeatureSelector, createSelector } from '@ngrx/store';

import { contactAdapter, State } from './contact.state';
import { ContactType } from '@surface-elements/contacts/domain';

const getContactFeatureState = createFeatureSelector<State>('contacts');

export const getContacts = createSelector(
  getContactFeatureState,
  contactAdapter.getSelectors().selectAll
);

export const getContactByID = (contactId: string) =>
  createSelector(getContacts, (contacts) => {
    const contact = contacts.filter((contact) => contact._id === contactId);
    return contact[0];
  });

export const getAccountContacts = createSelector(getContacts, (contacts) =>
  contacts.filter((contact) => contact.contactType === ContactType.account)
);

export const getJobContacts = createSelector(getContacts, (contacts) =>
  contacts.filter((contact) => contact.contactType === ContactType.job)
);

export const getCurrentContactId = createSelector(
  getContactFeatureState,
  (state: State) => state.selectedContactId
);

export const getCurrentContact = createSelector(
  getContactFeatureState,
  getCurrentContactId,
  (state: State) => state.entities[state.selectedContactId]
);

export const getContactForm = createSelector(
  getContactFeatureState,
  (state: State) => state.contactForm
);

export const getError = createSelector(
  getContactFeatureState,
  (state) => state.error
);
