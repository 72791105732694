import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

/* NgRx */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as orderActions from './order.actions';

import { OrderDataService } from '../order-data.service';
import { ApiError } from '@surface-elements/shared/domain';

@Injectable()
export class OrderEffects {
  constructor(
    private actions$: Actions,
    private orderDataService: OrderDataService,
    private router: Router
  ) {}

  loadOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActions.loadOrders),
      mergeMap(() =>
        this.orderDataService.getOrders().pipe(
          map((orders) => orderActions.loadOrdersSuccess({ orders })),
          catchError((error) => of(orderActions.loadOrdersFailure({ error })))
        )
      )
    );
  });

  createOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActions.createOrder),
      mergeMap((payload) => {
        return this.orderDataService.createOrder(payload.order).pipe(
          map((order) => {
            return orderActions.createOrderSuccess({ order });
          }),
          catchError((error: ApiError) => {
            return of(
              orderActions.createOrderFail({ error: error.error.error.message })
            );
          })
        );
      })
    );
  });

  createOrderSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(orderActions.createOrderSuccess),
        tap((payload) => this.router.navigate(['/orders', payload.order._id]))
      );
    },
    { dispatch: false }
  );

  updateOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActions.updateOrder),
      mergeMap(({ order, id }) =>
        this.orderDataService.updateOrder(order, id).pipe(
          map(() => orderActions.updateOrderSuccess({ id, changes: order })),
          catchError((error) => of(orderActions.updateOrderFailure({ error })))
        )
      )
    );
  });

  updateOrdersInvoiceDate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActions.updateOrdersInvoiceDate),
      mergeMap((ordersPayload) =>
        this.orderDataService
          .updateOrdersInvoiceDate(
            ordersPayload.invoiceDate,
            ordersPayload.orderIds
          )
          .pipe(
            map((response: { invoiceDate: string; orderIds: string[] }) => {
              return response.orderIds.map((orderId) => {
                return {
                  id: orderId,
                  changes: {
                    invoiceDate: response.invoiceDate,
                    active: false,
                  },
                };
              });
            }),
            map((data) =>
              orderActions.updateOrdersInvoiceDateSuccess({ payload: data })
            ),
            catchError((error) =>
              of(orderActions.updateOrdersInvoiceDateFailure({ error }))
            )
          )
      )
    );
  });

  softDeleteOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActions.softDeleteOrder),
      mergeMap(({ order, jobNumber, id }) =>
        this.orderDataService
          .updateOrder({ _id: id, isDeleted: true }, id)
          .pipe(
            map(() =>
              orderActions.softDeleteOrderSuccess({ id, changes: order })
            ),
            tap(() => this.router.navigate(['/jobs', jobNumber])),
            catchError((error) =>
              of(orderActions.updateOrderFailure({ error }))
            )
          )
      )
    );
  });
}
