import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import {
  ReportConfig,
  ReportUserDefinedField,
  alignPosition,
} from '@surface-elements/reports/domain';

export interface ReportConfigFormValue {
  _id: string;
  reportName: string;
  systemReport: boolean;
  udfConfigs: [
    {
      udf: ReportUserDefinedField;
      config: {
        print: boolean;
        location: {
          preciseLocationEnabled: boolean;
          preciseLocation: {
            x: number;
            y: number;
          };
          defaultLocation: {
            sortPosition: number;
            alignPosition: alignPosition;
          };
        };
        text: {
          defaultUdfFont: string;
          defaultUdfFontSize: number;
          defaultResponseFont: string;
          defaultResponseFontSize: number;
        };
      };
    }
  ];
}

export const REPORT_CONFIG_FORM_ID = 'reportConfigForm';

export const initialReportConfigFormState =
  createFormGroupState<ReportConfigFormValue>(REPORT_CONFIG_FORM_ID, {
    _id: null,
    reportName: '',
    systemReport: false,
    udfConfigs: [
      {
        udf: {
          displayText: '',
          responseFormat: null,
        },
        config: {
          print: false,
          location: {
            preciseLocationEnabled: false,
            preciseLocation: {
              x: 0,
              y: 0,
            },
            defaultLocation: {
              sortPosition: 0,
              alignPosition: alignPosition.left,
            },
          },
          text: {
            defaultUdfFont: 'Helvetica-Bold',
            defaultUdfFontSize: 12,
            defaultResponseFont: 'Helvetica',
            defaultResponseFontSize: 12,
          },
        },
      },
    ],
  });

export interface State extends EntityState<ReportConfig> {
  error: string;
  reportConfigForm: FormGroupState<ReportConfigFormValue>;
}

export const reportConfigAdapter: EntityAdapter<ReportConfig> =
  createEntityAdapter<ReportConfig>({
    selectId: (reportConfig: ReportConfig) => reportConfig._id,
  });

export const defaultReportConfig: State = {
  ids: [],
  entities: {},
  error: '',
  reportConfigForm: initialReportConfigFormState,
};

export const initialState =
  reportConfigAdapter.getInitialState(defaultReportConfig);
