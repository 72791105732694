import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  RootStoreState,
  AuthStoreActions,
  AuthStoreSelectors,
} from '@surface-elements/shared/data-access';
import { User } from '@surface-elements/shared/shared-ui';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  isAuthenticated = false;
  user: User | null = null;
  public isMenuCollapsed = true;
  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store
      .select(AuthStoreSelectors.getAuthFeatureState)
      .subscribe((state) => {
        this.isAuthenticated = state.isAuthenticated;
        this.user = state.user;
      });
  }

  logOut(): void {
    this.store.dispatch(AuthStoreActions.logOut());
  }
}
