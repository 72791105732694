import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportConfig } from '@surface-elements/reports/domain';

import { State, reportConfigAdapter } from './report.state';

const getReportFeatureState = createFeatureSelector<State>('reportConfig');

export const getReportsConfig = createSelector(
  getReportFeatureState,
  reportConfigAdapter.getSelectors().selectAll
);

export const getReportConfigFromName = (reportName: string) =>
  createSelector(getReportsConfig, (reports: ReportConfig[]) => {
    return reports.find((report) => report.reportName === reportName);
  });

export const getReportConfigFromId = (reportConfigId: string) =>
  createSelector(getReportsConfig, (reports: ReportConfig[]) => {
    return reports.find((report) => report._id === reportConfigId);
  });

export const getReportUdfConfigfromReportID = (reportConfigId: string) =>
  createSelector(getReportsConfig, (reports: ReportConfig[]) => {
    return reports.find((report) => report._id === reportConfigId).udfConfigs;
  });

export const getReportMeasureUdfConfig = createSelector(
  getReportsConfig,
  (reportsConfig: ReportConfig[]) =>
    reportsConfig.find((report) => report.reportName === 'Measure')
);

export const getReportProductionUdfConfig = createSelector(
  getReportsConfig,
  (reportsConfig: ReportConfig[]) =>
    reportsConfig.find((report) => report.reportName === 'Production')
);

export const getReportInstallUdfConfig = createSelector(
  getReportsConfig,
  (reportsConfig: ReportConfig[]) =>
    reportsConfig.find((report) => report.reportName === 'Install')
);

export const getReportServiceUdfConfig = createSelector(
  getReportsConfig,
  (reportsConfig: ReportConfig[]) =>
    reportsConfig.find((report) => report.reportName === 'Service')
);

export const getReportConfigForm = createSelector(
  getReportFeatureState,
  (state: State) => state.reportConfigForm
);
