import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '@surface-elements/shared/environments';
import { SelectionStoreSelectors } from '@surface-elements/settings/data-access';
import { OrderStoreActions, OrderStoreSelectors } from '@surface-elements/orders/data-access';

import { Account } from '@surface-elements/accounts/domain';
import { Job } from '@surface-elements/jobs/domain';
import { Order } from '@surface-elements/orders/domain';
import { ReportStoreActions, ReportStoreSelectors } from './+state';
import { UdfConfig } from '@surface-elements/reports/domain';

interface Ticket {
  account: Account;
  job: Job;
  order: Order;
  udfConfig: UdfConfig[];
}

interface CombinedTicket {
  measure: Ticket;
  production: Ticket;
  install: Ticket;
  billing: Ticket;
}

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private reportsURL = `${environment.apiUrl}/reports`;

  constructor(private store: Store, private http: HttpClient) {
    this.store.dispatch(ReportStoreActions.loadReportsConfig());
    this.store.dispatch(ReportStoreActions.loadReportUserDefinedFields());
  }

  pdfMeasure(orderId: string, account: Account, job: Job): Observable<Blob> {
    let pdfMeasureBlob$: Observable<Blob>;
    let udfConfig: UdfConfig[] = [];
    this.store.dispatch(
      OrderStoreActions.setCurrentOrder({ currentOrderId: orderId })
    );
    this.store
      .select(ReportStoreSelectors.getReportMeasureUdfConfig)
      .pipe(take(1))
      .subscribe(
        (measureUdfConfig) => (udfConfig = measureUdfConfig.udfConfigs)
      );
    this.store
      .select(OrderStoreSelectors.getCurrentOrder)
      .pipe(take(1))
      .subscribe({
        next: (order) => {
          const measureTicket: Ticket = {
            account,
            job,
            order,
            udfConfig,
          };
          pdfMeasureBlob$ = this.http.post(
            `${this.reportsURL}/pdf/measure`,
            measureTicket,
            { responseType: 'blob' }
          );
        },
        error: (err) => {
          pdfMeasureBlob$ = of(err);
        },
      });

    return pdfMeasureBlob$;
  }

  pdfProduction(orderId: string, account: Account, job: Job): Observable<Blob> {
    let pdfProductionBlob$: Observable<Blob>;
    let udfConfig: UdfConfig[] = [];
    this.store.dispatch(
      OrderStoreActions.setCurrentOrder({ currentOrderId: orderId })
    );
    this.store
      .select(ReportStoreSelectors.getReportProductionUdfConfig)
      .pipe(take(1))
      .subscribe(
        (productionUdfConfig) => (udfConfig = productionUdfConfig.udfConfigs)
      );
    this.store
      .select(OrderStoreSelectors.getCurrentOrder)
      .pipe(take(1))
      .subscribe({
        next: (order) => {
          const productionTicket: Ticket = {
            account,
            job,
            order,
            udfConfig,
          };
          pdfProductionBlob$ = this.http.post(
            `${this.reportsURL}/pdf/production`,
            productionTicket,
            { responseType: 'blob' }
          );
        },
        error: (err) => {
          pdfProductionBlob$ = of(err);
        },
      });

    return pdfProductionBlob$;
  }

  pdfInstall(orderId: string, account: Account, job: Job): Observable<Blob> {
    let pdfMeasureBlob$: Observable<Blob>;
    let udfConfig: UdfConfig[] = [];
    this.store.dispatch(
      OrderStoreActions.setCurrentOrder({ currentOrderId: orderId })
    );
    this.store
      .select(ReportStoreSelectors.getReportInstallUdfConfig)
      .pipe(take(1))
      .subscribe(
        (installUdfConfig) => (udfConfig = installUdfConfig.udfConfigs)
      );
    this.store
      .select(OrderStoreSelectors.getCurrentOrder)
      .pipe(take(1))
      .subscribe({
        next: (order) => {
          let installCrew = '';
          this.store
            .select(
              SelectionStoreSelectors.getInstallerCrewFromId(order.installCrew)
            )
            .subscribe(
              (orderInstallCrew) => (installCrew = orderInstallCrew.crew)
            );
          const populatedOrder: Order = {
            ...order,
            installCrew,
          };
          const installTicket: Ticket = {
            account,
            job,
            order: populatedOrder,
            udfConfig,
          };
          pdfMeasureBlob$ = this.http.post(
            `${this.reportsURL}/pdf/install`,
            installTicket,
            { responseType: 'blob' }
          );
        },
        error: (err) => {
          pdfMeasureBlob$ = of(err);
        },
      });

    return pdfMeasureBlob$;
  }

  pdfService(
    orderId: string,
    serviceIndex: number,
    account: Account,
    job: Job
  ): Observable<Blob> {
    let pdfServiceBlob$: Observable<Blob>;
    let udfConfig: UdfConfig[] = [];
    this.store.dispatch(
      OrderStoreActions.setCurrentOrder({ currentOrderId: orderId })
    );
    this.store
      .select(ReportStoreSelectors.getReportServiceUdfConfig)
      .pipe(take(1))
      .subscribe(
        (serviceUdfConfig) => (udfConfig = serviceUdfConfig.udfConfigs)
      );
    this.store
      .select(OrderStoreSelectors.getCurrentOrder)
      .pipe(take(1))
      .subscribe({
        next: (order) => {
          const serviceTicket: Ticket = {
            account,
            job,
            order,
            udfConfig,
          };
          pdfServiceBlob$ = this.http.post(
            `${this.reportsURL}/pdf/service`,
            { serviceTicket, serviceIndex },
            { responseType: 'blob' }
          );
        },
        error: (err) => {
          pdfServiceBlob$ = of(err);
        },
      });
    return pdfServiceBlob$;
  }

  pdfBilling(
    orderId: string,
    serviceIndex: number,
    account: Account,
    job: Job
  ): Observable<Blob> {
    let pdfBillingBlob$: Observable<Blob>;
    this.store.dispatch(
      OrderStoreActions.setCurrentOrder({ currentOrderId: orderId })
    );
    this.store
      .select(OrderStoreSelectors.getCurrentOrder)
      .pipe(take(1))
      .subscribe({
        next: (order) => {
          const billingTicket: Ticket = {
            account,
            job,
            order,
            udfConfig: [],
          };
          console.log(order);
          pdfBillingBlob$ = this.http.post(
            `${this.reportsURL}/pdf/billing`,
            billingTicket,
            { responseType: 'blob' }
          );
        },
        error: (err) => {
          pdfBillingBlob$ = of(err);
        },
      });
    return pdfBillingBlob$;
  }

  pdfCombined(orderId: string, account: Account, job: Job): Observable<Blob> {
    let pdfCombinedBlob$: Observable<Blob>;
    let measureUdfTicketConfig: UdfConfig[] = [];
    let productionUdfTicketConfig: UdfConfig[] = [];
    let installUdfTicketConfig: UdfConfig[] = [];
    this.store.dispatch(
      OrderStoreActions.setCurrentOrder({ currentOrderId: orderId })
    );
    this.store
      .select(ReportStoreSelectors.getReportMeasureUdfConfig)
      .pipe(take(1))
      .subscribe(
        (measureUdfConfig) =>
          (measureUdfTicketConfig = measureUdfConfig.udfConfigs)
      );
    this.store
      .select(ReportStoreSelectors.getReportProductionUdfConfig)
      .pipe(take(1))
      .subscribe(
        (productionUdfConfig) =>
          (productionUdfTicketConfig = productionUdfConfig.udfConfigs)
      );
    this.store
      .select(ReportStoreSelectors.getReportInstallUdfConfig)
      .pipe(take(1))
      .subscribe(
        (installUdfConfig) =>
          (installUdfTicketConfig = installUdfConfig.udfConfigs)
      );
    this.store
      .select(OrderStoreSelectors.getCurrentOrder)
      .pipe(take(1))
      .subscribe({
        next: (order) => {
          const measureTicket: Ticket = {
            account,
            job,
            order,
            udfConfig: measureUdfTicketConfig,
          };
          const productionTicket: Ticket = {
            account,
            job,
            order,
            udfConfig: productionUdfTicketConfig,
          };
          const installTicket: Ticket = {
            account,
            job,
            order,
            udfConfig: installUdfTicketConfig,
          };
          const billingTicket: Ticket = {
            account,
            job,
            order,
            udfConfig: [],
          };
          const combinedOrder: CombinedTicket = {
            measure: measureTicket,
            production: productionTicket,
            install: installTicket,
            billing: billingTicket,
          };

          pdfCombinedBlob$ = this.http.post(
            `${this.reportsURL}/pdf/combined`,
            combinedOrder,
            { responseType: 'blob' }
          );
        },
        error: (err) => {
          pdfCombinedBlob$ = of(err);
        },
      });

    return pdfCombinedBlob$;
  }
}
