import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';

/* NgRx */
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ClearAsyncErrorAction,
  SetAsyncErrorAction,
  SetValueAction,
  StartAsyncValidationAction,
} from 'ngrx-forms';

import { ApiError } from '@surface-elements/shared/domain';
import { Account } from '@surface-elements/accounts/domain';
import { AccountDataService } from '../account-data.service';
import * as accountActions from './account.actions';
import * as accountSelectors from './account.selectors';
import { customDuplicateNameValidator } from './account.validators';

@Injectable()
export class AccountEffects {
  constructor(
    private actions$: Actions,
    private accountDataService: AccountDataService,
    private router: Router,
    private store: Store
  ) {}

  loadAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.loadAccounts),
      mergeMap(() =>
        this.accountDataService.getAccounts().pipe(
          map((accounts) => accountActions.loadAccountsSuccess({ accounts })),
          catchError((error) =>
            of(accountActions.loadAccountsFailure({ error }))
          )
        )
      )
    );
  });

  createAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.createAccount),
      mergeMap((payload) => {
        return this.accountDataService.createAccount(payload.account).pipe(
          map((data) => {
            return accountActions.createAccountSuccess(data);
          }),
          catchError((error: ApiError) => {
            return of(
              accountActions.createAccountFail({
                error: error.error.error.message,
              })
            );
          })
        );
      })
    );
  });

  createAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(accountActions.createAccountSuccess),
        tap((payload) => this.router.navigate(['/accounts', payload._id]))
      );
    },
    { dispatch: false }
  );

  deleteAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.deleteAccount),
      mergeMap((payload) => {
        return this.accountDataService.deleteAccount(payload.account).pipe(
          map(() => {
            return accountActions.deleteAccountSuccess({
              id: payload.account._id,
            });
          }),
          catchError((error: ApiError) => {
            return of(
              accountActions.deleteAccountFail({
                error: error.error.error.message,
              })
            );
          })
        );
      })
    );
  });

  updateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.updateAccount),
      mergeMap(({ account, id }) =>
        this.accountDataService.updateAccount(account, id).pipe(
          map((updateAccount: Account) =>
            accountActions.updateAccountSuccess({
              id: updateAccount._id,
              changes: updateAccount,
            })
          ),
          catchError((error) => of(accountActions.updateAccountFail({ error })))
        )
      )
    );
  });

  handleDeleteAccount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(accountActions.deleteAccountSuccess),
        tap(() => this.router.navigate(['/accounts']))
      );
    },
    { dispatch: false }
  );

  accountFormValidation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetValueAction.TYPE),
      filter((formControlUpdate: SetValueAction<string>) => {
        return formControlUpdate.controlId === 'accountForm.name';
      }),
      withLatestFrom(this.store.select(accountSelectors.getAccountNames)),
      mergeMap(([formControlUpdate, accountNames]) => {
        const error = 'duplicateName';
        return of(customDuplicateNameValidator(formControlUpdate.value, accountNames)).pipe(
          map((err) => {
            return !err
              ? new ClearAsyncErrorAction(formControlUpdate.controlId, error)
              : new SetAsyncErrorAction(formControlUpdate.controlId, error, 'Account name already exists');
          }),
          startWith(
            new StartAsyncValidationAction(formControlUpdate.controlId, error)
          )
        )
      })
    );
  });
}
