import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AccountEffects } from './account.effects';
import { accountReducer } from './account.reducer';

import { AccountDataService } from '../account-data.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('accounts', accountReducer),
    EffectsModule.forFeature([AccountEffects]),
  ],
  providers: [
    AccountDataService
  ]
})
export class AccountStoreModule {}
