import { createReducer, on } from '@ngrx/store';

import {
  createFormGroupState,
  onNgrxForms,
  updateArray,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import {
  email,
  maxLength,
  minLength,
  pattern,
  required,
} from 'ngrx-forms/validation';

import {
  initialState,
  initialFormState,
  contactAdapter,
  State,
  ContactFormValue,
  FORM_ID,
} from './contact.state';
import * as contactActions from './contact.actions';
import { Address, Email, Phone } from '@surface-elements/shared/shared-ui';

const reducer = createReducer<State>(
  initialState,
  onNgrxForms(),
  on(contactActions.resetMyForm, (state): State => {
    return {
      ...state,
      contactForm: createFormGroupState<ContactFormValue>(
        FORM_ID,
        initialFormState.value
      ),
    };
  }),
  on(contactActions.SetEditingValueContactAction, (state, action): State => {
    return {
      ...state,
      contactForm: createFormGroupState<ContactFormValue>(
        FORM_ID,
        action.editValue
      ),
    };
  }),
  on(contactActions.loadContactsSuccess, (state, action): State => {
    return contactAdapter.setAll(action.contacts, {
      ...state,
      error: '',
    });
  }),
  on(contactActions.loadContactsFailure, (state, action): State => {
    return {
      ...state,
      entities: {},
      error: action.error,
    };
  }),
  on(contactActions.updateContactSuccess, (state, action): State => {
    return contactAdapter.updateOne(action, state);
  }),
  on(contactActions.updateContactFailure, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(contactActions.createContactSuccess, (state, action): State => {
    state = {
      ...state,
      error: '',
      selectedContactId: action._id,
    };
    return contactAdapter.addOne(action, state);
  }),
  on(contactActions.createContactFail, (state, action): State => {
    return {
      ...state,
      selectedContactId: null,
      error: action.error,
    };
  }),
  on(contactActions.createAccountContactSuccess, (state, action): State => {
    state = {
      ...state,
      error: '',
      selectedContactId: action._id,
    };
    return contactAdapter.addOne(action, state);
  }),
  on(contactActions.createAccountContactFail, (state, action): State => {
    return {
      ...state,
      selectedContactId: null,
      error: action.error,
    };
  }),
  on(contactActions.linkAccountContactSuccess, (state, action): State => {
    return contactAdapter.updateOne(action, state);
  }),
  on(contactActions.linkAccountContactFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(contactActions.unlinkAccountContactSuccess, (state, action): State => {
    return contactAdapter.updateOne(action, state);
  }),
  on(contactActions.unlinkAccountContactFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(contactActions.createJobContactSuccess, (state, action): State => {
    state = {
      ...state,
      error: '',
      selectedContactId: action._id,
    };
    return contactAdapter.addOne(action, state);
  }),
  on(contactActions.createJobContactFail, (state, action): State => {
    return {
      ...state,
      selectedContactId: null,
      error: action.error,
    };
  }),
  on(contactActions.linkJobContactSuccess, (state, action): State => {
    return contactAdapter.updateOne(action, state);
  }),
  on(contactActions.linkJobContactFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(contactActions.unlinkJobContactSuccess, (state, action): State => {
    return contactAdapter.updateOne(action, state);
  }),
  on(contactActions.unlinkJobContactFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(contactActions.setCurrentContact, (state, action): State => {
    return {
      ...state,
      error: '',
      selectedContactId: action.currentContactId,
    };
  }),
  on(contactActions.clearCurrentContact, (state): State => {
    return {
      ...state,
      selectedContactId: null,
    };
  }),
  on(contactActions.clearSelectedIds, (state): State => {
    return {
      ...state,
      selectedIds: [],
    };
  })
);

const regUrl = new RegExp(
  '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
);

const validateContactForm = updateGroup<ContactFormValue>({
  firstName: validate(required),
  addresses: updateArray(
    updateGroup<Address>({
      addressType: validate(required),
    })
  ),
  phones: updateArray(
    updateGroup<Phone>({
      number: validate(minLength(10), maxLength(10)),
      type: validate(required),
    })
  ),
  emails: updateArray(
    updateGroup<Email>({
      emailAddress: validate(email),
      type: validate(required),
    })
  ),
  website: validate(pattern(regUrl)),
});

export const contactReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.contactForm,
  validateContactForm
);
