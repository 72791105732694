import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent, FormSaved } from '@surface-elements/shared/shared-ui';

@Injectable({
  providedIn: 'root',
})
export class FormCanDeactivateGuard {
  constructor(private dialog: NgbModal) {}
  canDeactivate(
    component: FormSaved
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!component.isFormSaved()) {
      const modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        size: 'md',
      };
      const modalRef = this.dialog.open(ConfirmDialogComponent, modalOptions);
      return modalRef.closed;
    }
    return true;
  }
}
