import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Job } from '@surface-elements/jobs/domain';
import { environment } from '@surface-elements/shared/environments';
import { Contact } from '@surface-elements/contacts/domain';

@Injectable({
  providedIn: 'root',
})
export class JobDataService {
  private jobsUrl = `${environment.apiUrl}/jobs`;

  constructor(private http: HttpClient) {}

  getJobs(): Observable<Job[]> {
    return this.http.get<Job[]>(this.jobsUrl);
  }

  createJob(payload: Job): Observable<Job> {
    return this.http
      .post<Job>(this.jobsUrl, payload)
      .pipe(catchError((error: string) => throwError(error)));
  }

  updateJob(payload: Partial<Job>, id: string): Observable<Job> {
    return this.http.patch<Job>(`${this.jobsUrl}/${id}`, payload);
  }

  createJobContact(payload: Contact, jobId: string): Observable<Contact> {
    const apiUrl = `${this.jobsUrl}/${jobId}/contacts`;
    return this.http
      .post<Contact>(apiUrl, payload)
      .pipe(catchError((error: string) => throwError(error)));
  }
}
