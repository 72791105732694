import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import {
  ReportConfig,
  ReportUserDefinedField,
} from '@surface-elements/reports/domain';
import { ReportConfigFormValue } from './report.state';

// load reports config
export const loadReportsConfig = createAction(
  '[REPORT CONFIG] Load Reports Config'
);

export const loadReportsConfigSuccess = createAction(
  '[REPORT CONFIG] Load Reports Config Success',
  props<{ reportConfigs: ReportConfig[] }>()
);

export const loadReportsConfigFailure = createAction(
  '[REPORT CONFIG] Load Reports Config Fail',
  props<{ error: string }>()
);

// update report config
export const updateReportsConfig = createAction(
  '[REPORT CONFIG] Update Reports Config',
  props<{ reportConfig: ReportConfig; id: string }>()
);

export const updateReportsConfigSuccess = createAction(
  '[REPORT CONFIG] Update Reports Config Success',
  props<Update<ReportConfig>>()
);

export const updateReportsConfigFailure = createAction(
  '[REPORT CONFIG] Update Reports Config Fail',
  props<{ error: string }>()
);

// delete report config
export const deleteReportsConfig = createAction(
  '[REPORT CONFIG] Delete Reports Config',
  props<{ reportConfigsId: string }>()
);

export const deleteReportsConfigFail = createAction(
  '[REPORT CONFIG] Delete Reports Config Fail',
  props<{ error: string }>()
);

export const deleteReportsConfigSuccess = createAction(
  '[REPORT CONFIG] Delete Reports Config Success',
  props<{ id: string }>()
);

// report config form
export const SetEditingValueReportsConfigAction = createAction(
  '[REPORT CONFIG FORM] Set editing value',
  props<{ editValue: ReportConfigFormValue }>()
);

// load report user defined fields
export const loadReportUserDefinedFields = createAction(
  '[REPORT UDF] Load Report User Defined Fields'
);

export const loadReportUserDefinedFieldsSuccess = createAction(
  '[REPORT UDF] Load Reports User Defined Fields Success',
  props<{ reportUserDefinedFields: ReportUserDefinedField[] }>()
);

export const loadReportUserDefinedFieldsFailure = createAction(
  '[REPORT UDF] Load Reports User Defined Fields Fail',
  props<{ error: string }>()
);

// delete report user defined field
export const deleteReportUserDefinedField = createAction(
  '[REPORT UDF] Delete Reports User Defined Field',
  props<{ reportUserDefinedFieldId: string }>()
);

export const deleteReportUserDefinedFieldFail = createAction(
  '[REPORT UDF] Delete Reports User Defined Field Fail',
  props<{ error: string }>()
);

export const deleteReportUserDefinedFieldSuccess = createAction(
  '[REPORT UDF] Delete Reports User Defined Field Success',
  props<{ id: string }>()
);
