import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@surface-elements/shared/data-access';

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): boolean {
    if (!this.auth.getToken()) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}
