<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<nav
  class="navbar navbar-expand-lg fixed-top bg-primary"
  data-bs-theme="light"
  style="min-width: 260px"
>
  <a class="navbar-brand" href="#">Surface Elements</a>
  <button
    class="navbar-toggler"
    type="button"
    (click)="isMenuCollapsed = !isMenuCollapsed"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="container-fluid pe-0">
    <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
      <ul class="navbar-nav me-auto" *ngIf="isAuthenticated">
        <li [routerLinkActive]="['active']" class="nav-item">
          <a
            class="nav-link"
            routerLink="/accounts"
            (click)="isMenuCollapsed = true"
            >Accounts</a
          >
        </li>
        <li [routerLinkActive]="['active']" class="nav-item">
          <a
            class="nav-link"
            routerLink="/jobs"
            (click)="isMenuCollapsed = true"
            >Jobs</a
          >
        </li>
        <li [routerLinkActive]="['active']" class="nav-item">
          <a
            class="nav-link"
            routerLink="/orders"
            (click)="isMenuCollapsed = true"
            >Orders</a
          >
        </li>
        <li [routerLinkActive]="['active']" class="nav-item">
          <a
            class="nav-link"
            routerLink="/contacts"
            (click)="isMenuCollapsed = true"
            >Contacts</a
          >
        </li>
        <li [routerLinkActive]="['active']" class="nav-item">
          <a
            class="nav-link"
            routerLink="/reports"
            (click)="isMenuCollapsed = true"
            >Reports</a
          >
        </li>
        <li
          *ngIf="user['role'] === 'ADMIN'"
          [routerLinkActive]="['active']"
          class="nav-item"
        >
          <a
            class="nav-link"
            routerLink="/settings"
            (click)="isMenuCollapsed = true"
            >Settings</a
          >
        </li>
      </ul>
      <div class="mx-2" style="width: 100%">
        <app-widget-search-bar *ngIf="isAuthenticated"></app-widget-search-bar>
      </div>
      <div>
        <ul class="navbar-nav" style="width: 100%; text-align: right">
          <li class="nav-item" *ngIf="!isAuthenticated">
            <a
              class="nav-link"
              routerLink="/login"
              (click)="isMenuCollapsed = true"
              >Login</a
            >
          </li>
          <div *ngIf="isAuthenticated">
            <div class="profile-name" style="width: 150px">
              {{ user?.firstName }} {{ user?.lastName }}
            </div>
            <li class="nav-item">
              <a
                class="nav-link pt-0"
                style="cursor: pointer"
                (click)="logOut()"
                >LogOut</a
              >
            </li>
          </div>
        </ul>
      </div>
      <div class="mx-2" *ngIf="isAuthenticated">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="#fff"
          class="bi bi-person-circle"
          viewBox="0 0 16 16"
        >
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          <path
            fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
          />
        </svg>
      </div>
    </div>
  </div>
</nav>
