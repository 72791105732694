import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

/* NgRx */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AccountStoreActions } from '@surface-elements/accounts/data-access';
import { AuthStoreActions } from './auth-store';
import { JobStoreActions } from '@surface-elements/jobs/data-access';
import { ContactStoreActions } from '@surface-elements/contacts/data-access';
import { OrderStoreActions } from '@surface-elements/orders/data-access';
import { ReportStoreActions } from '@surface-elements/reports/data-access';
import { SelectionStoreActions } from '@surface-elements/settings/data-access';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private spinner: NgxSpinnerService) {}

  showSpinner$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthStoreActions.login,
          AccountStoreActions.createAccount,
          AccountStoreActions.deleteAccount,
          AccountStoreActions.loadAccounts,
          AccountStoreActions.updateAccount,
          JobStoreActions.createJob,
          JobStoreActions.loadJobs,
          JobStoreActions.updateJob,
          ContactStoreActions.createAccountContact,
          ContactStoreActions.createContact,
          ContactStoreActions.createJobContact,
          ContactStoreActions.deleteContact,
          ContactStoreActions.linkAccountContact,
          ContactStoreActions.linkJobContact,
          ContactStoreActions.loadContacts,
          ContactStoreActions.unlinkAccountContact,
          ContactStoreActions.unlinkJobContact,
          ContactStoreActions.updateContact,
          OrderStoreActions.createOrder,
          OrderStoreActions.loadOrders,
          OrderStoreActions.softDeleteOrder,
          OrderStoreActions.updateOrder,
          ReportStoreActions.deleteReportUserDefinedField,
          ReportStoreActions.deleteReportsConfig,
          ReportStoreActions.loadReportUserDefinedFields,
          ReportStoreActions.loadReportsConfig,
          SelectionStoreActions.deleteColor,
          SelectionStoreActions.deleteProduct,
          SelectionStoreActions.deleteProfile,
          SelectionStoreActions.deleteSplash,
          SelectionStoreActions.deleteThickness,
          SelectionStoreActions.createColor,
          SelectionStoreActions.createProduct,
          SelectionStoreActions.createProfile,
          SelectionStoreActions.createSplash,
          SelectionStoreActions.createThickness,
          SelectionStoreActions.loadColorSelections,
          SelectionStoreActions.loadProductSelections,
          SelectionStoreActions.loadProfileSelections,
          SelectionStoreActions.loadSelectionOptions,
          SelectionStoreActions.loadSplashSelections,
          SelectionStoreActions.loadThicknessSelections,
          SelectionStoreActions.updateColor,
          SelectionStoreActions.updateProduct,
          SelectionStoreActions.updateProfile,
          SelectionStoreActions.updateSplash,
          SelectionStoreActions.updateThickness
        ),
        tap(() => this.spinner.show())
      );
    },
    { dispatch: false }
  );

  hideSpinner$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthStoreActions.loginFailure,
          AuthStoreActions.loginSuccess,
          AccountStoreActions.createAccountFail,
          AccountStoreActions.createAccountSuccess,
          AccountStoreActions.deleteAccountFail,
          AccountStoreActions.deleteAccountSuccess,
          AccountStoreActions.loadAccountsFailure,
          AccountStoreActions.loadAccountsSuccess,
          AccountStoreActions.updateAccountFail,
          AccountStoreActions.updateAccountSuccess,
          JobStoreActions.createJobFail,
          JobStoreActions.createJobSuccess,
          JobStoreActions.loadJobsFailure,
          JobStoreActions.loadJobsSuccess,
          JobStoreActions.updateJobFail,
          JobStoreActions.updateJobSuccess,
          ContactStoreActions.createAccountContactFail,
          ContactStoreActions.createAccountContactSuccess,
          ContactStoreActions.createContactFail,
          ContactStoreActions.createContactSuccess,
          ContactStoreActions.createJobContactFail,
          ContactStoreActions.createJobContactSuccess,
          ContactStoreActions.deleteContactFail,
          ContactStoreActions.deleteContactSuccess,
          ContactStoreActions.linkAccountContactFail,
          ContactStoreActions.linkAccountContactSuccess,
          ContactStoreActions.linkJobContactFail,
          ContactStoreActions.linkJobContactSuccess,
          ContactStoreActions.loadContactsFailure,
          ContactStoreActions.loadContactsSuccess,
          ContactStoreActions.unlinkAccountContactFail,
          ContactStoreActions.unlinkAccountContactSuccess,
          ContactStoreActions.unlinkJobContactFail,
          ContactStoreActions.unlinkJobContactSuccess,
          ContactStoreActions.updateContactFailure,
          ContactStoreActions.updateContactSuccess,
          OrderStoreActions.createOrderFail,
          OrderStoreActions.createOrderSuccess,
          OrderStoreActions.loadOrdersFailure,
          OrderStoreActions.loadOrdersSuccess,
          OrderStoreActions.softDeleteOrderSuccess,
          OrderStoreActions.updateOrderFailure,
          OrderStoreActions.updateOrderSuccess,
          ReportStoreActions.deleteReportUserDefinedFieldFail,
          ReportStoreActions.deleteReportUserDefinedFieldSuccess,
          ReportStoreActions.deleteReportsConfigFail,
          ReportStoreActions.deleteReportsConfigSuccess,
          ReportStoreActions.loadReportUserDefinedFieldsFailure,
          ReportStoreActions.loadReportUserDefinedFieldsSuccess,
          ReportStoreActions.loadReportsConfigFailure,
          ReportStoreActions.loadReportsConfigSuccess,
          SelectionStoreActions.deleteColorFail,
          SelectionStoreActions.deleteColorSuccess,
          SelectionStoreActions.deleteProductFail,
          SelectionStoreActions.deleteProductSuccess,
          SelectionStoreActions.deleteProfileFail,
          SelectionStoreActions.deleteProfileSuccess,
          SelectionStoreActions.deleteSplashFail,
          SelectionStoreActions.deleteSplashSuccess,
          SelectionStoreActions.deleteThicknessFail,
          SelectionStoreActions.deleteThicknessSuccess,
          SelectionStoreActions.createColorFail,
          SelectionStoreActions.createColorSuccess,
          SelectionStoreActions.createProductFail,
          SelectionStoreActions.createProductSuccess,
          SelectionStoreActions.createProfileFail,
          SelectionStoreActions.createProfileSuccess,
          SelectionStoreActions.createSplashFail,
          SelectionStoreActions.createSplashSuccess,
          SelectionStoreActions.createThicknessFail,
          SelectionStoreActions.createThicknessSuccess,
          SelectionStoreActions.loadColorSelectionsFailure,
          SelectionStoreActions.loadColorSelectionsSuccess,
          SelectionStoreActions.loadProductSelectionsFailure,
          SelectionStoreActions.loadProductSelectionsSuccess,
          SelectionStoreActions.loadProfileSelectionsFailure,
          SelectionStoreActions.loadProfileSelectionsSuccess,
          SelectionStoreActions.loadSelectionOptionsFailure,
          SelectionStoreActions.loadSelectionOptionsSuccess,
          SelectionStoreActions.loadSplashSelectionsFailure,
          SelectionStoreActions.loadSplashSelectionsSuccess,
          SelectionStoreActions.loadThicknessSelectionsFailure,
          SelectionStoreActions.loadThicknessSelectionsSuccess,
          SelectionStoreActions.updateColorFail,
          SelectionStoreActions.updateColorSuccess,
          SelectionStoreActions.updateProductFail,
          SelectionStoreActions.updateProductSuccess,
          SelectionStoreActions.updateProfileFail,
          SelectionStoreActions.updateProfileSuccess,
          SelectionStoreActions.updateSplashFail,
          SelectionStoreActions.updateSplashSuccess,
          SelectionStoreActions.updateThicknessFail,
          SelectionStoreActions.updateThicknessSuccess
        ),
        tap(() => this.spinner.hide())
      );
    },
    { dispatch: false }
  );
}
