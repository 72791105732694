import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFormGroupState, FormGroupState } from 'ngrx-forms';

import { Order } from '@surface-elements/orders/domain';
import { AreaSelection, Service } from '@surface-elements/shared/shared-ui';

export interface OrderFormValue {
  isDeleted: boolean;
  _id: string;
  orderNumber: number;
  job: string;
  quoteSqFt: number;
  quotedAmount: number;
  customerPO: string;
  actualSqFt: number;
  actualAmount: number;
  estInstallHours: string;
  vendorPO: string;
  statusNote: string;
  invoiceDate: string;
  active: boolean;
  numberOfTops?: number;
  numberOfSplashes?: string;
  numberOfBrackets?: number;
  sinkLocation?: string;
  installCrew?: string;
  tearoutBySEI?: boolean;
  bracketsRequired?: boolean;
  sinkModelsInfo?: string;
  areaSelections?: AreaSelection[];
  services?: Service[];
}

export const ORDER_FORM_ID = 'orderForm';

export const INITIAL_ORDER_FORM_STATE = createFormGroupState<OrderFormValue>(ORDER_FORM_ID, {
  isDeleted: false,
  _id: null,
  orderNumber: null,
  job: null,
  quoteSqFt: null,
  quotedAmount: null,
  customerPO: '',
  actualSqFt: null,
  actualAmount: null,
  estInstallHours: '',
  vendorPO: '',
  statusNote: '',
  invoiceDate: null,
  active: null,
  numberOfTops: null,
  numberOfSplashes: '',
  numberOfBrackets: null,
  sinkLocation: '',
  installCrew: '',
  tearoutBySEI: null,
  bracketsRequired: null,
  sinkModelsInfo: '',
  areaSelections: [],
  services: [],
});

export interface OrderInvoicingFormValue {
  orders: {
    orderId: string;
    orderNumber: number;
    accountName: string;
    jobName: string;
    vendorPO: string;
    orderDate: string;
    isSelected: boolean;
  }[];
  invoiceDate: string;
}

export const INVOICING_FORM_ID = 'orderInvoicingForm';

export const INITIAL_INVOICING_FORM_STATE = createFormGroupState<OrderInvoicingFormValue>(
  INVOICING_FORM_ID,
  {
    orders: [],
    invoiceDate: new Date(Date.now()).toISOString(),
  }
);
export interface State extends EntityState<Order> {
  selectedOrderId: string | null;
  selectedIds: string[] | null;
  error: string;
  orderForm: FormGroupState<OrderFormValue>;
  orderInvoicingForm: FormGroupState<OrderInvoicingFormValue>;
}

export const orderAdapter: EntityAdapter<Order> = createEntityAdapter<Order>({
  selectId: (order: Order) => order._id,
});

export const defaultOrder: State = {
  ids: [],
  entities: {},
  selectedOrderId: null,
  selectedIds: [],
  error: '',
  orderForm: INITIAL_ORDER_FORM_STATE,
  orderInvoicingForm: INITIAL_INVOICING_FORM_STATE,
};

export const initialState = orderAdapter.getInitialState(defaultOrder);
