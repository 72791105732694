import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reportReducer } from './report.reducer';
import { ReportEffects } from './report.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('reportConfig', reportReducer),
    EffectsModule.forFeature([ReportEffects]),
  ],
})
export class ReportStoreModule {}
