import { createReducer, on } from '@ngrx/store';

import {
  State,
  initialState,
  reportConfigAdapter,
  ReportConfigFormValue,
  REPORT_CONFIG_FORM_ID,
} from './report.state';

import * as reportActions from './report.actions';
import { createFormGroupState, onNgrxForms } from 'ngrx-forms';

export const reportReducer = createReducer<State>(
  initialState,
  onNgrxForms(),
  on(
    reportActions.SetEditingValueReportsConfigAction,
    (state, action): State => {
      return {
        ...state,
        reportConfigForm: createFormGroupState<ReportConfigFormValue>(
          REPORT_CONFIG_FORM_ID,
          action.editValue
        ),
      };
    }
  ),
  on(reportActions.loadReportsConfigSuccess, (state, action): State => {
    return reportConfigAdapter.setAll(action.reportConfigs, {
      ...state,
      error: '',
    });
  }),
  on(reportActions.loadReportsConfigFailure, (state, action): State => {
    return {
      ...state,
      entities: {},
      error: action.error,
    };
  }),
  on(reportActions.updateReportsConfigSuccess, (state, action): State => {
    return reportConfigAdapter.updateOne(action, state);
  }),
  on(reportActions.updateReportsConfigFailure, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(reportActions.deleteReportsConfigSuccess, (state, { id }): State => {
    return reportConfigAdapter.removeOne(id, state);
  }),
  on(reportActions.deleteReportsConfigFail, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  })
);
