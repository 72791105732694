import { createFeatureSelector, createSelector } from '@ngrx/store';

import { accountAdapter, State } from './account.state';
import * as AccountReducer from './account.reducer';

const getAccountFeatureState = createFeatureSelector<State>('accounts');

export const selectAllEntities = createSelector(
  getAccountFeatureState,
  AccountReducer.selectEntities
);

export const selectEntityById = createSelector(
  selectAllEntities,
  (entities, props) => entities[props.id]
);

export const getAccounts = createSelector(
  getAccountFeatureState,
  accountAdapter.getSelectors().selectAll
);

export const getActiveAccounts = createSelector(getAccounts, (accounts) =>
  accounts.filter((account) => account.status === 'Active')
);

export const getInactiveAccounts = createSelector(getAccounts, (accounts) =>
  accounts.filter((account) => account.status === 'Inactive')
);

export const getCurrentAccountId = createSelector(
  getAccountFeatureState,
  (state: State) => state.selectedAccountId
);

export const getCurrentAccount = createSelector(
  getAccountFeatureState,
  getCurrentAccountId,
  (state: State) => state.entities[state.selectedAccountId]
);

export const getSelectedIds = createSelector(
  getAccountFeatureState,
  (state: State) => state.selectedIds
);

export const getSelectedAccounts = createSelector(
  getAccounts,
  getSelectedIds,
  (accounts, ids) => accounts.filter((account) => ids.indexOf(account._id) > -1)
);

export const getError = createSelector(
  getAccountFeatureState,
  (state) => state.error
);

export const getAccountByID = (accountId: string) =>
  createSelector(getAccounts, (accounts) => {
    const account = accounts.filter((account) => account._id === accountId);
    return account[0];
  });

export const getAccountContacts = (accountId: string) =>
  createSelector(getAccountByID(accountId), (account) => account.contacts);

export const getAccountForm = createSelector(
  getAccountFeatureState,
  (state: State) => state.accountForm
);

export const selectAccountFormName = createSelector(
  getAccountFeatureState,
  (state: State) => state.accountForm.controls.name
);

export const getAccountNames = createSelector(getAccounts, (accounts) => {
  return accounts.map((account) => account.name);
});
